import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import * as Yup from "yup";


function TextLighting({ open, setOpen }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setOpen(false);
    const handleShow = () => setShow(true);

    const myStyle = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#12062C'
    }

    return (
        <>
            <Modal show={open} onHide={handleClose} animation={true} size='lg' centered={true} >
                <Modal.Body style={myStyle} >
                    <h3 className='section-title__title mt-2 text-center'>Aydınlatma Metni</h3>
                    <p>Omega olarak, 07 Nisan 2016 tarihinde yürürlüğe giren 6698 Sayılı
                        Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca, Veri Sorumlusu sıfatıyla hazırladığımız
                        işbu bilgilendirme yazısı ile sizlere, Kanun’da yer alan “Veri Sorumlusunun Aydınlatma
                        Yükümlülüğü” başlıklı 10. maddesi ve “İlgili Kişinin Hakları” başlıklı 11. Maddeleri başta olmak
                        üzere Kanun çerçevesinde; kişisel verilerinizin hangi amaçla işlenebileceği, kimlere ve hangi
                        amaçlarla aktarılabileceği, kişisel verilerinizin toplanmasının yöntemi ve hukuki sebebi ile ilgili
                        olarak sizleri bilgilendirmek isteriz.</p>
                    <p>Kişisel Verilerin İşlenmesinin Hukuki Sebepleri: Kanun gereğince, kişisel verileriniz,
                        Tarafımızca Veri Sorumlusu sıfatıyla, Kanun’un 5. Maddesin c) bendinde belirtilen “Bir
                        sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
                        taraflarına ait kişisel verilerin işlenmesinin gerekli olması.” Sebebiyle Kanun’un izin verdiği ölçüde
                        işlenebilecektir.</p>
                    <p>Kişisel Verilerin İşleme Amaçları: Kişisel verileriniz, Kanun’un 5. ve 6. maddelerinde belirtilen
                        kişisel veri işleme şartları ve amaçları çerçevesinde, Omega
                        tarafından sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına
                        göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması
                        ve icrası, Omega tarafından sunulan ürün ve hizmetlerden ilgili
                        kişileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması ve ilgili iş
                        süreçlerinin yürütülmesi, Omega tarafından yürütülen ticari
                        faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve
                        buna bağlı iş süreçlerinin yürütülmesi ve Omega ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş
                        güvenliğinin temini ve bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak amaçları
                        dahilinde işlenmektedir.</p>
                    <p>Kişisel Verilerin Paylaşılabileceği Taraflar ve Paylaşım Amaçları: Kişisel verileriniz, Kanun’un
                        8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde ve yukarıda
                        belirtilen işleme amaçları doğrultusunda Omega ile iş ilişkisi
                        içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş güvenliğinin temini amaçları dahilinde
                        Omega’nin iş ortakları ve tedarikçileri ile hukuken yetkili kurum ve
                        kuruluşlar ile hukuken yetkili özel hukuk tüzel kişileriyle paylaşılabilecektir.
                        İşlenen Kişisel Veriler: Ad, Soyad, TC Kimlik No, E-posta, Telefon ve Adres bilgileri
                        Kişisel Verilerin Toplanma Yöntemi: Kişisel verileriniz, internet ve mobil uygulamalarımız
                        aracılığı ile toplanmaktadır. Yukarıda belirtilen hukuki sebeplerle toplanan kişisel veriler Kanun’un
                        5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında bu metinde
                        belirtilen amaçlarla işlenebilmekte ve aktarılabilmektedir.
                        Veri Sahiplerinin Hakları ve Bu Hakların Kullanılması: Kanun’un “İstisnalar” başlıklı 28.
                        maddesinde öngörülen haller saklı kalmak kaydıyla, Kanun’un 11. maddesi çerçevesinde
                        tarafımıza yazılı olarak başvurarak kişisel verilerinizin;</p>
                    <p>a. İşlenip işlenmediğini öğrenme,</p>
                    <p>b. Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</p>
                    <p>c. Kişisel verilerinizin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını
                        öğrenme,</p>
                    <p>d. Kişisel verilerinizin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme,</p>
                    <p>e. Kişisel verilerinizin eksik veya yanlış işlenmiş ise düzeltilmesini isteme,.</p>
                    <p>f. Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini
                        veya yok edilmesini isteme,</p>
                    <p>g. Kişisel verilerinizin aktarıldığı üçüncü kişilere yukarıda sayılan (d) ve (e) bentleri
                        uyarınca yapılan işlemlerin bildirilmesini isteme,</p>
                    <p>h. Kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi nedeniyle
                        aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</p>
                    <p>i. Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde
                        zararın giderilmesini talep etme haklarına sahip bulunmaktasınız.
                        Veri Sahipleri Tarafından Hakların Kullanılması: Veri sahipleri, yukarıda bahsi geçen hakları
                        kullanmak için internet sitesinde yer alan “Kişisel Veri Sahibi Tarafından Veri Sorumlusuna
                        Yapılacak Başvurulara ilişkin Form”u kullanabileceklerdir. Başvurular, ilgili veri sahibinin kimliğini
                        tespit edecek belgelerle birlikte, aşağıdaki yöntemlerden biri ile gerçekleştirilecektir:</p>
                    <ul>
                        <li>Formun doldurularak ıslak imzalı kopyasının elden, noter aracılığı ile veya iadeli taahhütlü
                            mektupla 60 Cleveland St, Bt Tower, Londra, Birleşik Krallık adresine iletilmesi</li>
                        <li>Formun 5070 sayılı Elektronik İmza Kanunu kapsamında düzenlenen güvenli elektronik
                            imza ile imzalanarak info@omega-market.com adresine kayıtlı elektronik posta ile
                            gönderilmesi</li>
                        <li>Kişisel Verileri Koruma Kurulu tarafından öngörülen bir yöntemin izlenmesi
                        Omega, Kanun’da öngörülmüş sınırlar çerçevesinde söz konusu
                            hakları kullanmak isteyen veri sahiplerine, yine Kanun’da öngörülen şekilde azami otuz (30) gün
                            içerisinde cevap vermektedir. Kişisel veri sahipleri adına üçüncü kişilerin başvuru talebinde
                            bulunabilmesi için veri sahibi tarafından başvuruda bulunacak kişi adına noter kanalıyla
                            düzenlenmiş özel vekâletname bulunmalıdır.</li>
                    </ul>
                    <p>Veri sahibi başvuruları kural olarak ücretsiz olarak işleme alınmakla birlikte, Kişisel Verileri
                        Koruma Kurulu tarafından öngörülen ücret tarifesi üzerinden ücretlendirme yapılabilecektir.
                        Omega, başvuruda bulunan kişinin kişisel veri sahibi olup
                        olmadığını tespit etmek adına ilgili kişiden bilgi talep edebilir, başvuruda belirtilen hususları
                        netleştirmek adına, kişisel veri sahibine başvurusu ile ilgili soru yöneltebilir.</p>
                </Modal.Body>
                <Button className="thm-btn contact-one__btn bg-none" onClick={() => handleClose()}><span>Kapat</span></Button>
            </Modal>
        </>
    );
}

export default TextLighting