import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import BG1 from '../../../assets/images/background/banner2.png'
import BG2 from '../../../assets/images/shapes/about-three-s-1.png'
import BG3 from '../../../assets/images/services/platforms.png'
import Breadcumb from '../../Breadcumb/Main'
import { Helmet } from "react-helmet";

const login = "sca." + window.location.hostname.replace('www.', '')
const wt = "wt." + window.location.hostname.replace('www.', '')

const Service2 = () => {
  const logoOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: false,
    dots: false,
    margin: 30,
    items: 2,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 30,
        items: 2
      },
      375: {
        margin: 30,
        items: 2
      },
      575: {
        margin: 30,
        items: 3
      },
      767: {
        margin: 50,
        items: 4
      },
      991: {
        margin: 40,
        items: 5
      },
      1199: {
        margin: 80,
        items: 5
      }
    }
  }
  return (
    <>
      {/* <Breadcumb Title="İşlem Platformlarımız" Breadcumb="İşlem Platformları" /> */}
      <section className="section-padding--top section-padding--bottom about-two mt-5">
        <Helmet>
          <meta name="description"
            content="Omega’le son teknoloji işlem platformlarında trade yapmanın keyfini çıkarın. Omega farkıyla yeni nesil forex deneyime hazır olun !" />
          <title>İşlem Platformları - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
        </Helmet>
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-two__content">
                <div className="section-title ">
                  <h1 className="section-title__text my-2">Omega İşlem Platformları</h1>
                  <h2 className="section-title__title">Son Teknoloji İşlem Platformlarımız ile Trade Yapmanın Keyfini Çıkartın!</h2>
                </div>
                <div className="about-two__text">Tüm kullanıcılarına hızlı,güvenilir ve kesintisiz işlem imkanı sunan ticaret platformu.
                  Tek tıklamayla yürütme, sezgisel kontrol paneli ve gelişmiş göstergelere ve grafik analizine kolay erişime sahip kullanıcı
                  dostu arayüz, Sirix'i her ticaret seviyesi ve tarzı için tercih edilen platform haline getirir. Sirix, hareket halindeyken
                  sorunsuz bir ticaret deneyimi için Web, Masaüstü ve Mobil cihazlarda mevcuttur.</div>
                <ul className="about-two__list">
                  <li>
                    Hızlı
                  </li>
                  <li>
                    Güvenilir
                  </li>
                </ul>
                {/* <Link to="/about" className="thm-btn about-two__btn"><span>Learn More</span></Link> */}

              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-two__image">
                <img src="assets/images/services/platforms.png" alt="" className="wow fadeInUp"
                  data-wow-duration="1500ms" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding--top section-padding--bottom service-three black-bg"
        style={{ backgroundImage: `url(${BG1})` }}>
        <div className="container">
          <div className="service-three__block">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-title">
                  <p className="section-title__text">PLATFORMLARIMIZ</p>
                  <h2 className="section-title__title section-title__title--light">Güncel Son Teknoloji Platformlarımızda Trade işlemlerinizi Rahatlıkla Yapabilirsiz!</h2>
                </div>
              </div>
            </div>
          </div>
          <ul className="service-three__list">
            <a href={"https://" + wt} target="_blank">
              <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="000ms">
                <i className="icon-coding service-three__list__icon"></i>
                <h3 className="service-three__list__title"><Link to="#">Web</Link></h3>
              </li>
            </a>
            <a href="https://apps.apple.com/al/app/box-tool/id6499506604" target="_blank">
              <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="100ms">
                <i className="icon-apple service-three__list__icon"></i>
                <h3 className="service-three__list__title"><Link to="#">IOS</Link></h3>
              </li>
            </a>
            <a href="https://play.google.com/store/search?q=app4world&c=apps&gl=TR" target="_blank">
              <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="200ms">
                <i className="icon-android service-three__list__icon"></i>
                <h3 className="service-three__list__title"><Link to="#">Android</Link></h3>
              </li>
            </a>
            {/* <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
              data-wow-delay="300ms">
              <i className="icon-iot service-three__list__icon"></i>
              <h3 className="service-three__list__title"><Link to="#">IOT</Link></h3>
            </li>
            <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
              data-wow-delay="400ms">
              <i className="icon-smartband service-three__list__icon"></i>
              <h3 className="service-three__list__title"><Link to="#">Wearalables</Link></h3>
            </li>
            <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
              data-wow-delay="500ms">
              <i className="icon-tv service-three__list__icon"></i>
              <h3 className="service-three__list__title"><Link to="#">TV</Link></h3>
            </li> */}
          </ul>
        </div>
      </section>

      <section className="section-padding--bottom section-padding--top about-three">
        <div className="about-three__shape wow fadeInRight" data-wow-duration="1500ms"
          style={{ backgroundImage: `url(${BG2})` }}>
        </div>
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-three__image">
                <img src="assets/images/services/sirix.png" className="wow fadeInUp"
                  data-wow-duration="1500ms" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-three__content">
                <div className="section-title ">
                  <p className="section-title__text">Sirix Ayrıcalıkları</p>
                  <h2 className="section-title__title">Neden Sirix?</h2>
                </div>
                <ul className="about-three__list">
                  <li className="about-three__list__item d-flex align-items-center">
                    <div className="about-three__list__icon">
                      <i className="icon-analysis"></i>
                    </div>
                    <div className="about-three__list__content">
                      <p className="about-three__list__text">WebTrader, TabletTrader ve MobilTrader işlem imkânı sunar</p>
                    </div>
                  </li>

                  <li className="about-three__list__item d-flex align-items-center">
                    <div className="about-three__list__icon">
                      <i className="icon-system"></i>
                    </div>
                    <div className="about-three__list__content">
                      <p className="about-three__list__text">İndirme ve kuruluma gerek yoktur</p>
                    </div>
                  </li>
                  <li className="about-three__list__item d-flex align-items-center">
                    <div className="about-three__list__icon">
                      <i className="icon-cloud"></i>
                    </div>
                    <div className="about-three__list__content">
                      <p className="about-three__list__text">Tüm cihazlarda kolaylıkla kullanılabilir</p>
                    </div>
                  </li>

                  <li className="about-three__list__item d-flex align-items-center">
                    <div className="about-three__list__icon">
                      <i className="icon-dashboard"></i>
                    </div>
                    <div className="about-three__list__content">
                      <p className="about-three__list__text">İnternet bağlantısı yeterlidir</p>
                    </div>
                  </li>

                  <li className="about-three__list__item d-flex align-items-center">
                    <div className="about-three__list__icon">
                      <i className="icon-consulting"></i>
                    </div>
                    <div className="about-three__list__content">
                      <p className="about-three__list__text">Saniyeler içerisinde Al-sat yapılabilir</p>
                    </div>
                  </li>

                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="section-padding--bottom">
        <div className="mx-4">
          <div className="section-title text-center">
            <h2 className="section-title__title">Omega Sirix İçin Minimum <br />Sistem Gereksinimleri</h2>

          </div>
          <div className="row gutter-y-30 d-flex justify-content-center">
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">

                <p className="service-card-two__text">Microsoft Windows 11/10/8/7</p>
                <div className="service-card-two__icon">
                  <i className="icon-smart-tv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <p className="service-card-two__text">2.0 GHz veya daha hızlı CPU</p>
                <div className="service-card-two__icon">
                  <i className="icon-link"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <p className="service-card-two__text">1GB RAM veya daha üstü RAM</p>
                <div className="service-card-two__icon">
                  <i className="icon-technical-support"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <p className="service-card-two__text">En az 1024x768 pixel ekran çözünürlüğü</p>
                <div className="service-card-two__icon">
                  <i className="icon-cctv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg">
                <p className="service-card-two__text">128 kbps veya daha hızlı bir internet bağlantısı</p>
                <div className="service-card-two__icon">
                  <i className="icon-system"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Service2