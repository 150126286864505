import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'

const ForexAnalytical = () => {
	return (
		<>
			{/* <Breadcumb Title="FOREX ANALİTİĞİ" Breadcumb="FOREX" /> */}
			<section className="section-padding--bottom section-padding--top service-details--page mt-5">
				<div className="container">
					<div className="row ">
						<div className="col-lg-8">
							<div className="service-details__image">
								<img src="assets/images/services/analtic.jpg" alt="" />
							</div>
							<h3 className="service-details__title">Forex Analitiği</h3>
							<div className="service-details__content">
								<p>Birikimlerinizi kısa vadede avantajlı şekilde değerlendirebilmek için Forex Analitiği nasıl öğrenilir, analiz yöntemleri nelerdir ve analizler nasıl yapılır konularında bilgi sahibi olmalısınız. Çünkü profesyoneller gibi kâr getirecek portföy oluşturabilmeniz için inceleme yapmanız ve analizleri doğru yorumlamanız gerekmektedir. İnceleme yaparken de, hangi taktiği kullanmanız gerekliliği ve hangi analizin diğerine göre ön planda tutulması gerektiği önem arz etmektedir. Bu kapsamda Forex nasıl öğrenilir ve Forex Analitiği nasıl yapılır yazımızda açıkladık!</p>
								<h4>Forex Analitiği Nasıl Öğrenilir?</h4>
								<p>Tüm dünya üzerinden yönetilen Forex piyasası günlük yaklaşık 6 trilyon Dolar işlem hacmine sahiptir. Böylesine yüksek bir işlem hacminin var olduğu küresel bir piyasada, kısa vadede yüksek kazançlar elde edebilirsiniz. Fakat bilgi yetersizliği sebebiyle yapacağınız küçük bir hata, büyük kayıplar yaşamanıza neden olabilir. Bu nedenle Forex Analitiği nasıl yapılır öğrenmeniz ve olası hataları ortadan kaldırmanız gerekiyor</p>
								<p>İnternette Forex başarılarıyla ilgili birçok hikayeye ulaşabilirsiniz. Nasıl ki borsa zenginleri olarak adını duyurmuş olan ünlü yatırımcılar mevcut ise Forex’te de profesyonel traderlar bulunmaktadır. Bu traderların deneyimlerine ve hikayelerine göz atarsanız, hepsinin belirli bir bilgi birikimi edindikten sonra yüksek kazançlar elde ettiğini fark edersiniz. Bu da demek oluyor ki; Forex nasıl öğrenilir gibi temel bilgileri edinmenin ve analizler sayesinde piyasada tecrübe kazanmanın önemi büyük!</p>
								<h4>Forex Analitiği Nasıl Yapılır?</h4>
								<p>Forex Analitiği nasıl öğrenilir konusunu 2 ana başlıkta inceleyebiliriz. Bunlardan ilki temel analizdir ve kısaca makro verilerin Forex piyasası üzerinde fiyatlanma biçimi olarak yansımasıdır. Bir ülke borsalarında ya da para biriminde Forex işlemi gerçekleştirirken, ilgili ülkenin ekonomik-siyasi verilerinin oluşturduğu etkileri ve bu etkilerin sonucunun parite/emtia/endeks tarafına nasıl yansıdığını gösteren temel analizdir.</p>
								<p>Analiz yöntemlerinin bir diğeri ise teknik analizdir. Grafiklere dayalı bu analiz sayesinde trend ve indikatörlerin ürettiği sinyaller ile piyasada kazanma şansınızı arttırabilirsiniz. Çünkü teknik analizin temel varsayımı; ilgili ürün için geçmişte oluşan fiyat hareketlerinin yeniden oluşacağını üzerine kuruludur. Her iki analiz yöntemi de, dünyanın en likit ve en büyük işlem hacmine sahip finans piyasasında başarı sağlamanızı kolaylaştıracaktır!</p>
							</div>
							
						</div>
						<div className="col-lg-4 sidebar-column">
							<ServiceSidebar />
						</div>
					</div>
				</div>
			</section>
		</>)
}

export default ForexAnalytical