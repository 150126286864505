import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import BG1 from '../../assets/images/background/cta-two-bg-1-1.jpg'
import BG2 from '../../assets/images/shapes/testi-bg-1-1.png'
import Breadcumb from '../Breadcumb/Main'
import { Parallax } from 'react-parallax'
import { Helmet } from "react-helmet";

const About = () => {
	const options = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 700,
		items: 1,
		margin: 0,
		nav: false,
		dots: false,
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			576: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			}
		}
	}
	const logoOptions = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		nav: true,
		navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
		dots: false,
		margin: 30,
		items: 2,
		smartSpeed: 700,
		responsive: {
			0: {
				margin: 30,
				items: 2
			},
			375: {
				margin: 30,
				items: 2
			},
			575: {
				margin: 30,
				items: 3
			},
			767: {
				margin: 50,
				items: 4
			},
			991: {
				margin: 40,
				items: 5
			},
			1199: {
				margin: 80,
				items: 5
			}
		}
	}

	return (
		<>
			{/* <Breadcumb Title="Hakkımızda" Breadcumb="Hakkımızda" /> */}
			<section className="project-details section-padding--bottom section-padding--top mt-5">
				<Helmet>
					<meta name="description"
						content="Omega hakkında tüm merak ettiklerinize ve aklınızdaki soru işaretlerine ulaşabilirsiniz. Omega ayrıcalıklarını öğrenmek için tıklayın !" />
					<title>Hakkımızda - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
				</Helmet>
				<div className="container">
					<h1 className="project-details__title">Omega Hakkında</h1>
					<div className="project-details__content">
						<p>Operasyonel faaliyetleri maksimum özveri ile yapan Omega Invest’in son derece eğitimli ve tecrübeli uzman kadrosu, güncel teknolojiyle donatılmış alt yapısı ve müşteri odaklı hizmet anlayışı ile müşterilerine, kalite standartları yüksek ve hızlı bir hizmet sunmaktadır. Omega Invest çalışanları, ulusal ve global piyasalardaki gelişmeleri yakından takip ederek, kurumsal ve bireysel yatırımcı tabanıyla geniş bir kitleye hizmet vermektedir. Ayrıca Omega Invest’in uzman kadrosu, hafta içi her gün uluslararası yayın yapan kanallara bağlantılar gerçekleştirerek, global ve Türkiye özelindeki ekonomik gelişmeleri değerlendirmekte, finansal piyasalar hakkında merak edilenleri açıklamaktadır.</p>
						<p>Yaptığı sponsorluk anlaşmaları, üniversite iş birlikleri ve sosyal sorumluluk projeleri ile de dikkat çeken Omega Invest, finansal okur yazarlığa verdiği destekle de sektöründe ilklerin öncüsü olmuştur. Omega Invest yaptığı yenilikçi ve öncü faaliyetleri neticesinde Uluslararası platformlar tarafından da birçok ödüle layık görülmüştür ve aynı zamanda bir çok kurum ve kuruluş tarafından Forex piyasalarında isminden bahsedilmiştir.</p>
						<p>2015 yılında çıktığı yolda emin ve güvenilir adımlarla ilerlemeye devam Omega Invest, faaliyet gösterdiği tüm alanlarda sürdürülebilirliğe ve sektörün gelişimine katkı sağlamayı hedeflemektedir. Sektörle beraber kullanıcılarını da her geçen gün geliştirerek onlara benzersiz deneyimler kazandırmaktadır.</p>

						<h5 style={{ fontWeight: 'bolder' }} className='mt-4 text-dark'>Omega Invest'in müşterilerine sunduğu hizmetler:</h5>
						<ul className="project-details__list">
							<li>
								<i className="fa fa-check-circle"></i>
								Halka Arz,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Pay Piyasası İşlemleri,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								VİOP İşlemleri,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Kredili İşlemler,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Yatırım Danışmanlığı,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Açığa Satış İşlemleri,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Bireysel Portföy Yöneticiliği,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Forex İşlemleri,
							</li>
							<li>
								<i className="fa fa-check-circle"></i>
								Kurumsal Finansman.
							</li>

						</ul>
					</div>
				</div>
			</section>
			{/* <section className="section-padding--top section-padding--bottom">
				<div className="container">
					<div className="section-title text-center">
						<h2 className="section-title__title">Hizmetlerimiz</h2>
					</div>

					<OwlCarousel className="thm-owl__carousel" {...options}>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-1.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-2.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-3.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-4.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-5.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="item">
							<div className="project-card-one">
								<div className="project-card-one__image">
									<img src="assets/images/projects/project-1-6.jpg" alt="" />
								</div>
								<div className="project-card-one__content">
									<div className="project-card-one__content__inner">
										<p className="project-card-one__text">IT Technology Solution</p>
										<h3 className="project-card-one__title"><Link to="/project-details">Data Recovery
											Analysis</Link></h3>
										<Link to="/project-details" className="project-card-one__more">
											<i className="fa fa-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</OwlCarousel>
				</div>
			</section> */}
		</>
	)
}

export default About