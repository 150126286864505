import React from 'react'
import { Link } from 'react-router-dom'
import error from '../../assets/images/background/404.png'

function NotFound() {
    return (
        <div className='row mb-5 not-found-page-container'>
            <div className='col-md-6 '>
                <img src={error} alt='Error_404' className=' offset-md-5 h-100 img-fluid' />
            </div>
            <div className='col-md-6 d-flex align-items-center'>
                <div className="not-found-page-text"><p style={{ color: '#D4AF37', fontSize: '3rem' }}>Sayfa Bulunamadı! </p>
                    <Link to="/" className='thm-btn contact-one__btn'><span>Anasayfaya Dön!</span></Link></div>
            </div>
        </div>
    )
}

export default NotFound