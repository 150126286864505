import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonalData from './PersonalData';
import TextLighting from './TextLighting';
import { toast } from 'react-toastify';
import baseService from '../../services/BaseService';
import MaskedInput from "react-text-mask";

const phoneRegExp = /^(((\+|00)?(90)|0)[-| ]?)?((5\d{2})[-| ]?(\d{3})[-| ]?(\d{2})[-| ]?(\d{2}))$/gm

const LoginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Lütfen İsminizi Giriniz"),
  surname: Yup.string()
    .required("Lütfen Soyisminizi Giriniz"),
  email: Yup.string()
    .email("Lütfen Geçerli Bir Email Adresi Giriniz")
    .required("Lütfen Mail Adresinizi Giriniz"),
  phone: Yup.string()
    .required('Lütfen Telefon Numaranızı Giriniz.'),
  acceptTerms: Yup.bool().oneOf([true], 'Lütfen Kişisel Verilerin Korunmasını Ve Aydınlatma Metnini Onaylayınız')
});

function DemoAccountModal({ open, setOpenDemo }) {
  let brandType = 0
  var formUrl = window.location.protocol + "//" + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
  // let formUrl = `https://regnum.testanka.com/api/v1/Submit?brand=${brandType}`;

  let timeIntervel;

  const [show, setShow] = useState(false);
  const [openPersonalData, setOpenPersonalData] = useState(false)
  const [openLighting, setOpenLighting] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [time, setTime] = useState(0)

  const handleClose = () => setOpenDemo(false);
  const handleShow = () => setShow(true);

  const myStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#12062C'
  }
  const buttonStyle = {
    border: 'none',
    color: '#D4AF37',
    backgroundColor: '#12062C',
    textDecoration: 'underline',
    marginLeft: '3px',
    cursor: 'pointer'

  }
  const phoneNumberMask = [
    "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/
  ];
  const onSubmit = async (values) => {
    setSubmitting(true)
    var data = {
      "firstName": values.name,
      "lastName": values.surname,
      "email": values.email,
      "phone": String(values.phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", "")
    }
    // var data = {
    //   domain: window.location.host.replace("contact", ""),
    //   formSystemName: "DemoAccountForm",
    //   formValues: {
    //     "firstName": values.name,
    //     "lastName": values.surname,
    //     "email": values.email,
    //     "phone": String(values.phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
    //     "country": "Turkey",
    //     "countryCode": "+90",
    //     "message": "",
    //     "subject": "",
    //     "approvedConditions": true
    //   },
    //   languageIsoCode: 'tr',
    //   siteSystemName: 'RegnumFxWeb',

    // }
    await baseService.post(formUrl, data).then(async (response) => {
      if (response.success) {
        // toast.success('Lütfen Üyeliğinizi Aktif Etmek İçin Lütfen E-mail Kutunuzu Kontrol Ediniz.', { theme: 'dark', position: 'top-center' })
        handleTime()
      } else {
        // toast.error('Bir Hata Oluştu Lütfen Sayfayı Yenileyip Tekrar Deneyiniz', { theme: 'dark', position: 'top-center' })
      }
    })
    setSubmitting(false);

  }

  //timer settings start
  let timer = new Date()
  const handleTime = () => {
    let clickTime = timer.getTime()
    localStorage.setItem('regnumTimeDemo', clickTime)
    setTime(600000)
    reduceTime()
  }

  const reduceTime = async () => {
    if (localStorage.getItem('regnumTimeDemo')) {
      timeIntervel = setInterval(() => {
        setTime((time) => time - 1000)
      }, 1000);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('regnumTimeDemo')) {
      let newTime = timer.getTime()
      let oldTime = localStorage.getItem('regnumTimeDemo')
      let timeDifference = newTime - oldTime
      if (timeDifference > 600000) {
        setTime(0)
      } else {
        setTime((600000 - timeDifference))
        reduceTime()
      }
    }
  }, [])

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    if (millis === 0 || millis < 0) {
      localStorage.removeItem('regnumTimeDemo')
      setTime(0)
      clearInterval(timeIntervel)
    }
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  //timer settings end


  return (
    <>
      <Modal show={open} onHide={handleClose} animation={true} size='lg' centered={true} >
        <Modal.Header closeButton />
        <Modal.Body style={myStyle} >
          <h2 className='section-title__title mt-2 text-center'>Demo Hesap Oluştur</h2>
          <Formik
            initialValues={{ name: "", surname: "", email: "", phone: "", acceptTerms: false }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              onSubmit(values)

            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>

                <Modal.Body>
                  <div className='row mb-4'>
                    <div className="form-group col-md-6">
                      <label style={{ color: '#D4AF37' }} className='' htmlFor="email">İsim</label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="İsim"
                        className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="name"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label style={{ color: '#D4AF37' }} className='model-text' htmlFor="email">Soyisim</label>
                      <Field
                        type="text"
                        name="surname"
                        placeholder="Soyisim"
                        className={`form-control ${touched.surname && errors.surname ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="surname"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label style={{ color: '#D4AF37' }} htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email Adresi"
                        className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>


                    <div className="form-group col-md-6">
                      <label style={{ color: '#D4AF37' }} htmlFor="password">Telefon Numarası</label>
                      <Field
                        name="phone"
                        render={({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={phoneNumberMask}
                            placeholder='Telefon Numarası'
                            id="phone"
                            type="text"
                            className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""
                              }`}
                          />
                        )}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone"
                        className="invalid-feedback"
                      />
                    </div>
                    <div style={{ color: '#D4AF37' }} className="form-group col-md-12 mt-4">
                      <label>
                        <Field type="checkbox" name="acceptTerms" className={` ${touched.acceptTerms && errors.acceptTerms ? "is-invalid" : ""
                          }`} />
                      </label>
                      <label style={buttonStyle} onClick={() => setOpenPersonalData(true)}>Kişisel Verilerin Korunması</label> ve<label style={buttonStyle} onClick={() => setOpenLighting(true)}>Aydınlatma Metni</label> Okudum Onaylıyorum.
                      {touched.acceptTerms && errors.acceptTerms ? <p style={{ fontSize: '14px' }} className="text-danger">Lütfen Kişisel Verilerin Korunmasını Ve Aydınlatma Metnini Onaylayınız</p> : null}
                      <ErrorMessage
                        component="div"
                        name="acceptTerms"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    {time > 0 ? <button type="submit"
                      className="thm-btn about-one__btn mt-2"
                      disabled={true}
                    >
                      <span>{millisToMinutesAndSeconds(time)}</span></button> :
                      <button type="submit"
                        className="thm-btn about-one__btn mt-2"
                        disabled={submitting}
                      >
                        <span>{submitting ? "GÖNDERİLİYOR" : "Gönder"}</span></button>}
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik></Modal.Body>
      </Modal>
      <PersonalData open={openPersonalData} setOpen={setOpenPersonalData} />
      <TextLighting open={openLighting} setOpen={setOpenLighting} />
    </>
  );
}

export default DemoAccountModal