import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import CampaignList from '../Campaigns'
import { Helmet } from 'react-helmet'

const Projects = () => {
	return (
		<>
			<section className="section-padding--bottom section-padding--top mt-5">
				<Helmet>
					<meta name="description"
						content="Omega farkıyla forex piyasalarına girmeye hazır mısınız? Hemen Omega’lı olun ; forex piyasalarının keyfini Omega’le çıkarın." />
					<title>Kampanyalar - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
				</Helmet>
				<div className="container">
					<div className="row gutter-y-30">
						{
							CampaignList.map((item) => {
								return (
									<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
										data-wow-delay="000ms" key={item.id}>
										<Link to={`/campaigns/${item.url}`} state={item}>
											<div className="project-card-one">
												<div className="project-card-one__image">
													<img src={`assets/${item.imageUrl.smallImage}`} alt="" />
												</div>
												<div className="project-card-one__content">
													<div className="project-card-one__content__inner">
														<h3 className="project-card-one__title"><div className='campaign-card-title'>{item.title}</div></h3>
														<div className="project-card-one__more">
															<i className="fa fa-angle-right"></i>
														</div>
													</div>
												</div>
											</div>
										</Link>
									</div>
								)
							})
						}
					</div>
				</div>
			</section>
		</>
	)
}

export default Projects