import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BG from '../../../assets/images/services/services-s-cta-1-1.jpg'
import DemoAccountModal from '../../Modals/DemoAccountModal'

const ServiceSidebar = () => {
  const [openDemo, setOpenDemo] = useState(false)
  
  return (
    <div className="sidebar">
      <div className="sidebar__item sidebar__item--category">
        <h3 className="sidebar__title">Kategoriler</h3>
        <ul className="sidebar__category">
          <li><Link to="/what-is-forex">Forex Nedir?</Link></li>
          <li><Link to="/forex-education">Forex Eğitimi</Link></li>
          <li><Link to="/forex-analytical">Forex Analitiği</Link></li>
          <li><Link to="/notices">Yasal Uyarılar</Link></li>
          <li><Link to="/cookie-policy">Çerez Politikası</Link></li>
        </ul>
      </div>
      <div className="sidebar__item sidebar__item--cta" onClick={() => setOpenDemo(true)}>
        <div className="sidebar__cta">
          <i className="sidebar__cta__icon icon-consulting"></i>
          <h3  style={{cursor: 'pointer'}} className="sidebar__cta__title">Hemen Demo Hesap Oluştur</h3>
        </div>
      </div>
      <DemoAccountModal open={openDemo} setOpenDemo={setOpenDemo}/>
    </div>
  )
}

export default ServiceSidebar