import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/logo-light.png'
import DemoAccountModal from '../Modals/DemoAccountModal';
import RealAccountModal from '../Modals/RealAccountModal';

const Footer = () => {
    const location = useLocation();
    const [openReal, setOpenReal] = useState(false)
    const [openDemo, setOpenDemo] = useState(false)
    const [footerPadding, setfooterPadding] = useState(false);
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        if (location.pathname === "/" || location.pathname === "/service-01") {
            setfooterPadding(true)
        } else {
            setfooterPadding(false)
        }
    }, [location])

    return (
        <>
            <div className={`footer-main section-padding--bottom ${footerPadding ? "footer-main--top-padding" : "section-padding--top"}`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo mb-4">
                                    <img src={Logo} width="165" height="70" alt="Omega" />
                                </Link>

                                {/* <ul className="footer-widget__social">
                                    <li><a href='https://twitter.com/regnummenkul' target='_blank'><i className="fab fa-twitter"></i></a></li>
                                    <li><a href='https://www.facebook.com/Regnummenkul-110766585298918/?_rdr' target='_blank'><i className="fab fa-facebook" target='_blank'></i></a></li>
                                    <li><a href='https://www.instagram.com/regnummenkul/' target='_blank'><i className="fab fa-instagram"></i></a></li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Şirket</h3>
                                <ul className="footer-widget__links">
                                    {/* <li>
                                        <Link to="/license">Lisansı Görüntüleyin</Link>
                                    </li> */}
                                    <li>
                                        <Link to="/about">Hakkımızda</Link>
                                    </li>
                                    <li>
                                        <Link to="/trading-platforms">İşlem Platformları</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={() => setOpenReal(true)}>Gerçek Hesap Oluştur</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={() => setOpenDemo(true)}>Demo Hesap Oluştur</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">İletişim</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Forex</h3>
                                <ul className="footer-widget__links">
                                    <li>
                                        <Link to="/what-is-forex">Forex Nedir?</Link>
                                    </li>
                                    <li>
                                        <Link to="/forex-education">Forex Eğitimi</Link>
                                    </li>
                                    <li>
                                        <Link to="/forex-analytical">Forex Analitiği</Link>
                                    </li>
                                    <li>
                                        <Link to="/notices">Yasal Uyarılar</Link>
                                    </li>
                                    <li>
                                        <Link to="/cookie-policy">Çerez Politikası</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">İletişim</h3>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <a href="tel:+447916556530">+44 7916 556530</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <a href="mailto:support@omega-market.com">
                                            support@omega-market.com</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        60 Cleveland St, Bt Tower, Londra, Birleşik Krallık
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; <span className="dynamic-year">{currentYear}</span> All Copyright by Omega</p>
                </div>
            </div>
            <RealAccountModal open={openReal} setOpenReal={setOpenReal} />
            <DemoAccountModal open={openDemo} setOpenDemo={setOpenDemo} />
        </>
    )
}

export default Footer