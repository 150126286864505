import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import { Helmet } from 'react-helmet'

function LegalNotices() {
	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				{/* <Breadcumb Title="Yasal Uyarılar" Breadcumb="FOREX" /> */}
				<section className="section-padding--bottom section-padding--top service-details--page mt-5">
					<Helmet>
						<meta name="description"
							content="Omega farkıyla forex piyasalarına girmeye hazır mısınız? Hemen Omega’li olun ; forex piyasalarının keyfini Omega’le çıkarın." />
						<title>Yasal Uyarılar - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
					</Helmet>
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src="assets/images/services/legal-notices.png" alt="" />
								</div>
								<h1 className="service-details__title">Yasal Uyarılar</h1>
								<div className="service-details__content">
									<p>Forex piyasası kaldıraçla çalışan bir pazardır. Kaldıraç özelliği ile yüksek seviyelerde getiri ve risk içermektedir. İlk etapta yatırdığınız paranın riske atabileceginiz kısmı kadar işlem yapmanızı öneririz. Forex yatırımları her yatırımcı için risk açısından uygun bir pazar olmayabilir. Yatırım yapmadan önce piyasanın içerdiği riskleri tam olarak anladığınızdan emin olunuz ve eğer gerek duyarsanız uzman yetkilimizden tavsiye ve yardım alınız.</p>
									<p>Web sayfamızda yer alan her türlü bilgi, grafik, araştırma sonuçları, rapor, görüş ve tavsiyeler genel anlamda bilgi vermek amacıyla hazırlanmış olup, sitede müşteriler ve diğer üçüncü şahısların alım satım kararlarını destekleyebilecek yeterli bilgi bulunmayabilir. Sitemizde bulunan, bu bilgilerdeki olası hata ve eksikliklerden ve bu bilgilere dayanılarak yapılan işlemlerden doğacak her türlü maddi/manevi zararlardan ve her ne şekilde olursa olsun üçüncü kişilerin uğrayabileceği her türlü zararlardan dolayı Omega Invest sorumlu tutulamaz.</p>
									<h4>Piyasası Araçları, Borsa ve Piyasa Bilgileri</h4>
									<p>Kaldıraçlı alım satım (forex ve CFD ürünler) işlemlerine ilişkin detaylı bilgiye <Link to='/what-is-forex'>Forex Nedir</Link> adresinden ulaşabilirsiniz.</p>
									<h4>Uyarı Metni</h4>
									<p>Bu sayfada yer alan bilgiler “ genel nitelikte” olup yatırımcılarımızın alım-satım kararlarını destekleyebilecek yeterli bilgi sayfada olmayabilir. Bu sayfada yer alan bilgiler hiçbir şekilde alım satım kararları oluşturmakta kullanılmamalı, alım satım önerisi olarak da kabul edilmemelidir.</p>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default LegalNotices