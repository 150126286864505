import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BG from '../../assets/images/background/contact-one-bg-1-1.png'
import Breadcumb from '../Breadcumb/Main'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import baseService from '../../services/BaseService';
import { toast } from 'react-toastify';
import MaskedInput from "react-text-mask";
import { Helmet } from 'react-helmet'

const LoginSchema = Yup.object().shape({
    name: Yup.string()
        .required("Lütfen İsminizi Giriniz"),
    surname: Yup.string()
        .required("Lütfen Soyisminizi Giriniz"),
    email: Yup.string()
        .email("Lütfen Geçerli Bir Email Adresi Giriniz")
        .required("Lütfen Mail Adresinizi Giriniz"),
    phone: Yup.string()
        .required('Lütfen Telefon Numaranızı Giriniz.'),
    message: Yup.string().required('Lütfen Mesajınızı Giriniz')
});

const Contact = () => {
    let brandType = 0;
    let formUrl = window.location.protocol + "//" + window.location.hostname + "/api/v1/Submit?brand=" + brandType;
    // let formUrl = `https://regnum.testanka.com/api/v1/Submit?brand=${brandType}`
    const [submitting, setSubmitting] = useState(false)
    const [time, setTime] = useState(0)
    let timeIntervel;


    const phoneNumberMask = [
        "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/
    ];

    //timer settings start
    let timer = new Date()
    const handleTime = () => {
        let clickTime = timer.getTime()
        localStorage.setItem('regnumTimeContact', clickTime)
        setTime(600000)
        reduceTime()
    }

    const reduceTime = async () => {
        if (localStorage.getItem('regnumTimeContact')) {
            timeIntervel = setInterval(() => {
                setTime((time) => time - 1000)
            }, 1000);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('regnumTimeContact')) {
            let newTime = timer.getTime()
            let oldTime = localStorage.getItem('regnumTimeContact')
            let timeDifference = newTime - oldTime
            if (timeDifference > 600000) {
                setTime(0)
            } else {
                setTime((600000 - timeDifference))
                reduceTime()

            }
        }
    }, [])

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        if (millis === 0 || millis < 0) {
            localStorage.removeItem('regnumTimeContact')
            setTime(0)
            clearInterval(timeIntervel)
        }
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    //timer settings end


    const onSubmit = async (values) => {
        setSubmitting(true)
        var data = {
            "firstName": values.name,
            "lastName": values.surname,
            "email": values.email,
            "phone": String(values.phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
            "message": values.message,
        }
        // var data = {
        //     domain: window.location.host.replace("contact", ""),
        //     formSystemName: "ContactForm",
        //     formValues: {
        //         "firstName": values.name,
        //         "lastName": values.surname,
        //         "email": values.email,
        //         "phone": String(values.phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
        //         "country": "Turkey",
        //         "countryCode": "+90",
        //         "message": values.message,
        //         "subject": "",
        //         "approvedConditions": true
        //     },
        //     languageIsoCode: 'tr',
        //     siteSystemName: 'RegnumFxWeb',

        // }
        await baseService.post(formUrl, data).then(async (response) => {
            if (response.success) {
                // toast.success('Lütfen Üyeliğinizi Aktif Etmek İçin Lütfen E-mail Kutunuzu Kontrol Ediniz.', { theme: 'dark', position: 'top-center' })
                handleTime()
            } else {
                // toast.error('Bir Hata Oluştu Lütfen Sayfayı Yenileyip Tekrar Deneyiniz', { theme: 'dark', position: 'top-center' })
            }
        })
        setSubmitting(false);
    }

    return (
        <>
            {/* <Breadcumb Title="İLETİŞİM" Breadcumb="İLETİŞİM" /> */}
            <section className="contact-one section-padding--top section-padding--bottom mt-5">
                <Helmet>
                    <meta name="description"
                        content="Omega farkıyla forex piyasalarına girmeye hazır mısınız? Hemen Omega’lı olun ; forex piyasalarının keyfini Omega ile çıkarın." />
                    <title>İletişim - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <Formik
                                initialValues={{ name: "", surname: "", email: "", phone: "", message: "" }}
                                validationSchema={LoginSchema}
                                onSubmit={(values) => {
                                    onSubmit(values)

                                }}>
                                {({ touched, errors, isSubmitting }) => (
                                    <Form className="contact-one__form contact-form-validated">
                                        <div className="section-title">
                                            <p className="section-title__text">BİZİMLE İLETİŞİME GEÇİN</p>
                                            <h2 className="section-title__title">Omega İle Hemen Kazanmaya Başlayın</h2>
                                        </div>
                                        <div className="row ">
                                            <div className="col-lg-8 col-md-12">
                                                <Field type="text" placeholder="İsminiz" name="name" className={`form-control ${touched.name && errors.name ? "is-invalid" : ""
                                                    }`} />
                                                <ErrorMessage
                                                    component="div"
                                                    name="name"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                            <div className="col-lg-8 col-md-12">
                                                <Field type="text" placeholder="Soyisminiz" name="surname" className={`form-control ${touched.surname && errors.surname ? "is-invalid" : ""
                                                    }`} />
                                                <ErrorMessage
                                                    component="div"
                                                    name="surname"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                            <div className="col-lg-8 col-md-12">
                                                <Field className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                                                    }`} type="email" placeholder="Email Adresiniz" name="email" />
                                                <ErrorMessage
                                                    component="div"
                                                    name="email"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                            <div className="col-lg-8 col-md-12">
                                                <Field
                                                    name="phone"
                                                    render={({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            placeholder='Telefon Numarası'
                                                            id="phone"
                                                            type="text"
                                                            className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""
                                                                }`}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="phone"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                            <div className="col-lg-8 col-md-12">
                                                <Field type="text" component="textarea" rows="4" name="message" placeholder="Mesajınız" className={`form-control ${touched.message && errors.message ? "is-invalid" : ""
                                                    }`} />
                                                <ErrorMessage
                                                    component="div"
                                                    name="message"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                {time > 0 ? <button type="submit"
                                                    className="thm-btn about-one__btn mt-2"
                                                    disabled={true}
                                                >
                                                    <span>{millisToMinutesAndSeconds(time)}</span></button> :
                                                    <button className="thm-btn contact-one__btn" type="submit" disabled={submitting}><span>{submitting ? "GÖNDERİLİYOR" : "Gönder"}</span></button>}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <div className="result"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms">
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Adres</h3>
                                    <p className="contact-one__item__text">60 Cleveland St, Bt Tower <br />
                                        Londra, Birleşik Krallık</p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Telefon</h3>
                                    <p className="contact-one__item__text">
                                        Mobil: <a href="tel:+447916556530">+44 7916 556530</a>
                                    </p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Email</h3>
                                    <p className="contact-one__item__text"><a
                                        href="mailto:support@omega-market.com">support@omega-market.com</a><br /></p>

                                </div>
                                {/* <div className="contact-one__item">
                                    <ul style={{ color: 'white' }} className="contact-one__social">
                                        <li><a href='https://twitter.com/regnummenkul' target='_blank'><i className="fab fa-twitter"></i></a></li>
                                        <li><a href='https://www.facebook.com/Regnummenkul-110766585298918/?_rdr'><i className="fab fa-facebook" target='_blank'></i></a></li>
                                        <li><a href='https://www.instagram.com/regnummenkul/' target='_blank'><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79469.99959720728!2d-0.16134939350989294!3d51.50518932906221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b29ba9f31d1%3A0xc88e383ae8ad82b!2sBT%20Tower!5e0!3m2!1str!2str!4v1678694052905!5m2!1str!2str" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{ width: '100%', height: '500px' }}></iframe>

            </section>
        </>
    )
}

export default Contact