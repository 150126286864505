const Campaigns = [
    {
        id: 1,
        title: '%50’YE VARAN BONUS',
        imageUrl: {
            smallImage: "images/campaigns/bonus/small-bonus.png",
            bigImage: "images/campaigns/bonus/big-bonus.png"
        },
        url: "yuzde-50-bonus",
        conditions: {
            1: "%50’ye varan bonus kampanyasından yararlanabilmek için Omega Invest ’de ilk kez gerçek hesap açıyor olmak gerekmektedir.",
            2: "Kampanyadan faydalanmak için gerekli minimum fonlama tutarı 200 Dolar olarak belirlenmiştir.",
            3: "Kampanyaya katılmak için gerekli ideal fonlama tutarı 500 Dolar olarak belirlenmiştir. 500$ altı yatırımlarda ilk yatırıma özel %30 bonus sağlanır. 500$ - 2000$ arası üstü yatırımlarda ilk yatırıma özel %40 bonus sağlanır. 2000$ üstü yatırımlarda ilk yatırıma özel %50 bonus sağlanmaktadır.",
            4: "Kampanyadan kazanılabilecek maksimum bonus tutarımız 2000$’dır.",
            5: "Kampanyaya katılım sağlandıktan sonra yapılan ekstra yatırımlar bu kampanyaya dahil edilemez.",
            6: "Bonuslar margin desteği için tanımlanır çekilemez, Hesaplar arası para transferinde ve çekim işlemlerinde bonus silinmektedir. Yatırımcıların kazanabileceği maksimum kayıp tutarı 400 Dolar’dır.",
            7: "Her bir yatırımcı kampanyadan yalnızca bir kez yararlanabilir.",
            8: "Kampanyaya katılmak için Omega Invest web sitesi iletişim kısmından, WhatsApp numaralarımızdan veya canlı destek kısmından müşteri temsilcilerimizle iletişime geçebilirsiniz.",
            9: "Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yaptığı tespit edilir ise hiçbir mazeret belirtmeden Omega Invest tarafından hesap kapatılıp yapılan tüm işlemler geçersiz sayılacaktır."
        },
        warnings: {
            1: "*Kampanyaya katılmak için 18 yaşından büyük olmanız gerekmektedir.",
            2: "*Omega Invest, %50’ye varan bonus promosyonundan kurallar değiştirme veya tamamen iptal etme hakkına sahiptir.Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcıların kendisine aittir.",
            3: "*Omega Invest, uzun yıllardır büyük aracı kurumların hazine biriminde tecrübesi bulunan çalışanları bünyesinde barındırmakta olup kural dışı faaliyetlerde bulunan, herhangi bir kötüye kullanım veya yasa dışı aktivite yapıldığı tespit edilen yatırımcılara karşı toleransın hiçbir şekilde sağlanmadığı politikasına uygun hareket edilmektedir.Bu tip yatırımcıların hesabının kapatılması, Omega Invest ile ilişiğinin tamamen kesilmesi ve hakkında yasal işlem başlatılması konusudur.",
            4: "*Bu kampanya farklı bir kampanya ile birleştirilemez."
        }
    },
    {
        id: 2,
        title: 'ÇEKİLEBİLİR %10 BONUS',
        imageUrl: {
            smallImage: "images/campaigns/cekilebilir-bonus/small-cekilebilir.png",
            bigImage: "images/campaigns/cekilebilir-bonus/big-cekilebilir.png"
        },
        url: "cekilebilir-10-bonus",
        conditions: {
            1: "Bu kampanyadan yararlanabilmek için Omega Invest ’de ilk defa gerçek hesap açıyor olmak gerekmektedir.",
            2: "Kampanya kapsamında minimum 500$ yatırıma %10 çekilebilir bonus eklenecektir.",
            3: "Referans olarak gelen yatırımcı 30 gün içinde %2 lot çevrim şartını tamamlamalıdır.",
            4: "Bu kampanyadan kazanılabilecek maksimum bonus tutarımız 2000$’dır.",
            5: "Tamamlamadığı durumda yatırımcının referansının çekilebilir bonusu çekilemez bonusa dönüştürülür.",
            6: "Kampanyaya katılım sağlandıktan sonra yapılan ekstra yatırımlar bu kampanyaya dahil edilmez.",
            7: "Her yatırımcı bu kampanyadan yalnızca bir defa yararlanabilir.",
            8: "Kampanyaya katılmak için Omega Invest web sitesi iletişim kısmından, WhatsApp numaralarımızdan veya canlı destekten müşteri temsilcileriyle iletişime geçebilirsiniz.",
            9: "Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yaptığı tespit edilirse hiçbir mazeret belirtmeden Omega Invest tarafından hesap kapatılıp yapılan tüm işlemler geçersiz sayılacaktır.",
            10: "Bu promosyon diğer promosyonlar ile birleştirilemez."
        },
        warnings: {
            1: "*Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yapıldığı tespit edilirse hiçbir mazeret belirtmeden Omega Invest tarafından hesap kapatılır ve yapılan tüm işlemler geçersiz sayılır.",
            2: "*Kampanyaya katılım 18 yaşından büyükler için geçerlidir.",
            3: "*Omega Invest, promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir.Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.",
            4: "*Omega Invest, uzun yıllardır büyük aracı kurumların hazine biriminde tecrübesi bulunan çalışanları bünyesinde barındırmakta olup kural dışı faaliyetlerde bulunan, herhangi bir kötüye kullanım veya yasa dışı aktivite yapıldığı tespit edilen yatırımcılara karşı toleransın hiçbir şekilde sağlanmadığı politikasına uygun hareket edilmektedir. Bu tip yatırımcıların hesabının kapatılması, Omega Invest ile ilişiğinin tamamen kesilmesi ve hakkında yasal işlem başlatılması söz konusudur.",
            5: "*Bu kampanya, farklı bir kampanya ile birleştirilemez."
        }
    },
    {
        id: 3,
        title: 'KRİPTO PARA ÜZERİNDEN ÖDEMELERDE KURUM TARAFINDAN İLK DEFA TEMASA GEÇİLDİĞİNDE YAPILAN FONLAMARA HOŞGELDİN BONUSUNA +%10 EK BONUS',
        imageUrl: {
            smallImage: "images/campaigns/kripto/small-kripto.png",
            bigImage: "images/campaigns/kripto/big-kripto.png"
        },
        url: "hosgeldin-10-ek-bonus",
        conditions: {
            1: "Kripto Para ödemelerinden Kurum tarafından ilk defa temasa geçildiğinde yapılan fonlamalara Hoş geldin Bonusuna + %10 ek bonus.",
            2: "Bonuslar margin desteği için tanımlanır çekilemez. Hesaplar arası para transferinde ve çekim işlemlerinde bonus silinir.",
            3: "Her yatırımcı bu kampanyadan yalnızca bir defa yararlanabilir.",
            4: "Kampanyaya katılmak için Omega Invest web sitesi iletişim kısmından, WhatsApp numaralarımızdan veya canlı destek ekibimizden müşteri temsilcileriyle iletişime geçebilirsiniz."
        },
        warnings: {
            1: "*Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yaptığı tespit edilirse hiçbir mazeret belirtmeden Omega Invest tarafından hesap kapatılıp yapılan tüm işlemler geçersiz sayılacaktır.",
            2: "*Kampanyaya katılmak için 18 yaşından büyük olmanız gerekmektedir.",
            3: "*Omega Invest, %50’ye varan bonus promosyonundan kurallar değiştirme veya tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcıların kendisine aittir.",
            4: "*Omega Invest, uzun yıllardır büyük aracı kurumların hazine birimindetecrübesi bulunan çalışanları bünyesinde barındırmakta olup kural dışı faaliyetlerde bulunan, herhangi bir kötüye kullanım veya yasa dışı aktivite yapıldığı tespit edilen yatırımcılara karşı toleransın hiçbir şekilde sağlanmadığı politikasına uygun hareket edilmektedir. Bu tip yatırımcıların hesabının kapatılması, Omega Invest ile ilişiğinin tamamen kesilmesi ve hakkında yasal işlem başlatılması söz konusudur.",
            5: "*Bu kampanya farklı bir kampanya ile birleştirilemez."
        }
    },
    {
        id: 4,
        title: 'LOT İADESİ',
        imageUrl: {
            smallImage: "images/campaigns/lot-iadesi/small-lot.png",
            bigImage: "images/campaigns/lot-iadesi/big-lot.png"
        },
        url: "lot-iadesi",
        conditions: {
            1: "Yatırımcı bu kampanyadan faydalandığı taktirde yapılan lot miktarı her ayın son iş günü hesaplanır ve diğer ayın ilk iş günü yatırımcıların hesabına aktarılır.",
            2: "Omega Invest Lot İadesi Kampanyası diğer kampanyalarla aynı anda kullanılamaz.",
            3: "Bu kampanyadan sadece Omega Invest ile doğrudan, herhangi bir aracı olmadan çalışan yatırımcılar yararlanabilir.",
            4: "Yatırımcı dilediği zaman kampanyayı sonlandırabilir ve o ay hakkı olan iadeyi alır, ama sonrasında firmamızla çalıştığı dönem boyunca sonraki aylarda herhangi bir iade hakkı kalmamış olur.",
            5: "Bu kampanyamızdan hak kazanmak için yatırımcının o ay içerisinde minimum 50 lot işlem yapması gerekmektedir. (Kurumumuz düşük spread uygulamaktadır.)",
            6: "Lot tutarı hesaplanırken yatırımcının FX, altın, Usoil ve Brent işlemleri göz önüne alınacaktır. Diğer Forex sembollerinde yapılacak işlemler hesaplamaya dahil edilmeyecektir.",
            7: "50 lot altındaki işlemlere herhangi bir ödeme yapılmaz.",
            8: "Yatırımcımızın alacağı iade o ayki yatırımı ile sınırlı olacaktır. Omega Invest; önceden haber vermeksizin kampanya kurallarını değiştirme veya iptal etme hakkına sahiptir."
        },
        warnings: {
            1: "*Kampanyaya katılmak için 18 yaşından büyük olmanız gerekmektedir.",
            2: "*Omega Invest, %50’ye varan bonus promosyonundan kurallar değiştirme veya tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcıların kendisine aittir.",
            3: "*Omega Invest, uzun yıllardır büyük aracı kurumların hazine biriminde tecrübesi bulunan çalışanları bünyesinde barındırmakta olup kural dışı faaliyetlerde bulunan, herhangi bir kötüye kullanım veya yasa dışı aktivite yapıldığı tespit edilen yatırımcılara karşı toleransın hiçbir şekilde sağlanmadığı politikasına uygun hareket edilmektedir. Bu tip yatırımcıların hesabının kapatılması, Omega Invest ile ilişiğinin tamamen kesilmesi ve hakkında yasal işlem başlatılması söz konusudur.",
            4: "*Bu kampanya farklı bir kampanya ile birleştirilemez."
        }
    },
    {
        id: 5,
        title: 'SABİT KUR KAMPANYA FIRSATLARINI KAÇIRMAYIN!',
        imageUrl: {
            smallImage: "images/campaigns/sabitkur/small-kur.png",
            bigImage: "images/campaigns/sabitkur/big-kur.png"
        },
        url: "sabit-kur-kampanya",
        conditions: {
            1: "500 USD ve üzeri yatırımlarınıza kuru 26’ya sabitliyoruz. 30 gün çekim talebi iletilmemesi durumunda, işlemlerde elde edilen karlar güncel kurdan çekilir.",
            2: "Ana para çekimi, girilen sabit kur üzerinden yapılır. Her yatırımcı bu kampanyadan yalnızca bir defa yararlanabilir.",
            3: "1000 USD ve üzerine yatırımlarınızda kuru 19.50’a sabitliyoruz.",
            4: "Kampanya kapsamında 30 gün çekim talebi iletilmemesi ve %2,5 oranında lot çevrilmesi durumunda tüm bakiye güncel kurdan çekilir.",
            5: "Hedge edilen işlemlerdeki lot sayısı dahil edilmemektedir."
        },
        warnings: {
            1: "*Kampanyaya katılmak için 18 yaşından büyük olmanız gerekmektedir.",
            2: "*Omega Invest, %30'a varan bonus promosyonundan kurallar değiştirme veya tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcıların kendisine aittir.",
            3: " *Omega Invest, uzun yıllardır büyük aracı kurumların hazine biriminde tecrübesi bulunan çalışanları bünyesinde barındırmakta olup kural dışı faaliyetlerde bulunan, herhangi bir kötüye kullanım veya yasa dışı aktivite yapıldığı tespit edilen yatırımcılara karşı toleransın hiçbir şekilde sağlanmadığı politikasına uygun hareket edilmektedir. Bu tip yatırımcıların hesabının kapatılması, Omega Invest ile ilişiğinin tamamen kesilmesi ve hakkında yasal işlem başlatılması söz konusudur."
        }
    },
    {
        id: 6,
        title: 'YATIRIM SİGORTASI',
        imageUrl: {
            smallImage: "images/campaigns/yatirim-sigortasi/small-yatirim.png",
            bigImage: "images/campaigns/yatirim-sigortasi/big-yatirim.png"
        },
        url: "yatirim-sigortasi",
        conditions: {
            1: "Bu kampanyadan yararlanabilmek için Omega Invest’ de ilk defa gerçek hesap açıyor olmak gerekir.",
            2: "İlk Yatırım Sigortası 15 iş günü içerisinde kayıp miktarı kadar kurum sigorta kapsamında çekilebilir bonus olarak tekrar girilecektir.",
            3: "Kampanyaya katılmak için gerekli minimum öz sermaye tutarı 1000$ olarak belirlenmiştir.",
            4: "Kampanyaya katılım sağlandıktan sonra yapılan ekstra yatırımlar bu kampanyaya dahil edilemez.",
            5: "Tanımlanan bonusun çekilebilmesi için minimum %2 lot çevirme şartı bulunmaktadır. Yatırımcının kazanabileceği maksimum kayıp tutarı 400 Dolar’dır.",
            6: "Her yatırımcı bu kampanyadan yalnızca bir defa yararlanabilir.",
            7: "Kampanyaya katılmak için Omega Invest web sitesi iletişim kısmından, WhatsApp numaralarımızdan veya canlı destekten müşteri temsilcileriyle iletişime geçebilirsiniz.",
            8: "Yatırımcının kural dışı faaliyetlerde bulunduğu ya da usulsüzlük yaptığı tespit edilirse hiçbir mazeret belirtmeden Omega Invest tarafından hesap kapatılıp yapılan tüm işlemler geçersiz sayılacaktır."
        },
        warnings: {
            1: "*Kampanyaya katılmak için 18 yaşından büyük olmanız gerekmektedir.",
            2: "*Omega Invest, %50’ye varan bonus promosyonundan kurallar değiştirme veya tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcıların kendisine aittir.",
            3: "*Omega Invest, uzun yıllardır büyük aracı kurumların hazine biriminde tecrübesi bulunan çalışanları bünyesinde barındırmakta olup kural dışı faaliyetlerde bulunan, herhangi bir kötüye kullanım veya yasa dışı aktivite yapıldığı tespit edilen yatırımcılara karşı toleransın hiçbir şekilde sağlanmadığı politikasına uygun hareket edilmektedir.Bu tip yatırımcıların hesabının kapatılması, Omega Invest ile ilişiğinin tamamen kesilmesi ve hakkında yasal işlem başlatılması söz konusudur.",
            4: "*Bu kampanya farklı bir kampanya ile birleştirilemez."
        }
    },
]

export default Campaigns;