import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import BG1 from '../../assets/images/background/banner1.png'
import BG2 from '../../assets/images/background/banner2.png'
import BG3 from '../../assets/images/background/banner3.png'
import BG4 from '../../assets/images/background/banner4.png'
import AnimatedNumber from "animated-number-react";
import MyNavbar from '../Navbar/MyNavbar';
import { Button } from 'react-bootstrap';
import RealAccountModal from '../Modals/RealAccountModal';
import { Helmet } from "react-helmet";

const Home3 = () => {
  const [sticky, setSticky] = useState(false);
  const [openReal, setOpenReal] = useState(false);

  const login = "sca." + window.location.hostname.replace('www.', '')
  const wt = "wt." + window.location.hostname.replace('www.', '')

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  }
  const logoOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
    dots: false,
    margin: 30,
    items: 2,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 30,
        items: 2
      },
      375: {
        margin: 30,
        items: 2
      },
      575: {
        margin: 30,
        items: 3
      },
      767: {
        margin: 50,
        items: 4
      },
      991: {
        margin: 40,
        items: 5
      },
      1199: {
        margin: 80,
        items: 5
      }
    }
  }
  const serviceOptions = {
    loop: false,
    nav: true,
    dots: false,
    items: 1,
    navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
    margin: 0,
    responsive: {
      0: {
        items: 1,
        margin: 0
      },
      768: {
        items: 1,
        margin: 30
      },
      1200: {
        items: 2,
        margin: 30
      },
      1700: {
        items: 3,
        margin: 30
      }
    }
  }
  const sliderOptions = {
    loop: true,
    items: 1,
    navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
    margin: 0,
    dots: true,
    nav: true,
    animateOut: "slideOutDown",
    animateIn: "fadeIn",
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: false
  }
  const projectOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: false,
    navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
    dots: false,
    margin: 0,
    items: 1,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 0,
        items: 1
      },
      768: {
        margin: 30,
        items: 2
      },
      992: {
        margin: 30,
        items: 3
      },
      1200: {
        margin: 30,
        items: 4
      }
    }
  }
  return (
    <>
      {/* <MyNavbar/> */}
      <div className="slider-one slider-three">
        <Helmet>
          <meta name="description"
            content="Omega farkıyla forex piyasalarına girmeye hazır mısınız? Hemen Omega’lı olun ; forex piyasalarının keyfini Omega’le çıkarın." />
          <title>Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
        </Helmet>
        <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...sliderOptions}>
          <div className="item slider-one__slide-1">
            <div className="slider-one__bg" style={{ backgroundImage: `url(${BG1})` }}>
            </div>
            <div className="slider-one__shape-1"></div>
            <div className="slider-one__shape-2"></div>
            <div className="container">
              <div className="slider-one__content text-center">
                <h1 className="slider-one__floated lettering-text">OMEGA INVEST</h1>
                <p className="slider-one__text">HOŞ GELDİNİZ!</p>
                <div className="slider-one__title-wrapper">
                  <h2 className="slider-one__title">Forex İşlemlerinde<br />Omega Farkı</h2>
                </div>
                {/* <div className="slider-one__btns">
                  <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="item slider-one__slide-2">
            <div className="slider-one__bg" style={{ backgroundImage: `url(${BG2})` }}>
            </div>
            <div className="slider-one__shape-1"></div>
            <div className="slider-one__shape-2"></div>
            <div className="container">
              <div className="slider-one__content text-center">
                <h1 className="slider-one__floated lettering-text">OMEGA INVEST</h1>
                <p className="slider-one__text">YATIRIM VE TRADE ADRESİNİZ</p>
                <div className="slider-one__title-wrapper">
                  <h2 className="slider-one__title">Küresel Finansal Piyasalara <br /> Erişim Adresiniz</h2>
                </div>
                {/* <div className="slider-one__btns">
                  <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="item slider-one__slide-3">
            <div className="slider-one__bg" style={{ backgroundImage: `url(${BG3})` }}>
            </div>
            <div className="slider-one__shape-1"></div>
            <div className="slider-one__shape-2"></div>
            <div className="container">
              <div className="slider-one__content text-center">
                <h1 className="slider-one__floated lettering-text">OMEGA INVEST</h1>
                <p className="slider-one__text">KÜRESEL PİYASALARDA</p>
                <div className="slider-one__title-wrapper">
                  <h2 className="slider-one__title">Daha İyi Bir Trade <br /> Deneyimini Keşfedin</h2>
                </div>
                {/* <div className="slider-one__btns">
                  <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="item slider-one__slide-4">
            <div className="slider-one__bg" style={{ backgroundImage: `url(${BG4})` }}>
            </div>
            <div className="slider-one__shape-1"></div>
            <div className="slider-one__shape-2"></div>
            <div className="container">
              <div className="slider-one__content text-center">
                <h1 className="slider-one__floated lettering-text">OMEGA INVEST</h1>
                <p className="slider-one__text">HEMEN BAŞLA</p>
                <div className="slider-one__title-wrapper">
                  <h2 className="slider-one__title">Bugün İşlem Yapmaya<br /> Başlayın</h2>
                </div>
                {/* <div className="slider-one__btns">
                  <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                </div> */}
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <section style={{ marginBottom: '-8rem' }} className="section-padding--top section-padding--bottom about-one">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-one__image">
                <img src="assets/images/resources/contact-1.png" className="wow fadeInUp"
                  data-wow-duration="1500ms" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-one__content">
                <div className="section-title ">
                  <h2 className="section-title__title">Omega Ailesine Katıl, sen de kazan!</h2>
                </div>
                <ul className="about-one__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    1:500 maksimum kaldıraç sayesinde yatırımlarınızı 500 katına kadar katlayın.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Omega ailesine özel bonus kampanyalarından faydalanın.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Kişiye özel yatırım danışmanlarımızla yatırımlarınızı daha güvenli yönetin.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Uzman kadromuzun sizler içim hazırladığı eğitimlerle Forex piyasasına hakim olun .
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    5/24 forex temsilciniz ile kesintisiz işlem yapmanın keyfini yaşayın.
                  </li>
                </ul>
                <button className="thm-btn about-one__btn" onClick={() => setOpenReal(true)}><span>Gerçek Hesap Oluştur</span></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding--bottom section-padding--top">
        <div className="container">

          <div className="row gutter-y-30">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg h-100">
                <h3 className="service-card-two__title"><Link to="/what-is-forex">Hemen Başlayın</Link></h3>
                <p className="service-card-two__text">Dünyanın en popüler ticaret platformu masaüstünde, web'de ve mobilde!</p>
                <div className="service-card-two__icon">
                  <i className="icon-smart-tv"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg h-100">
                <h3 className="service-card-two__title"><Link to="/trading-platforms">Rekabetçi Maliyetler</Link></h3>
                <p className="service-card-two__text">Rekabetçi maliyetler ticaretiniz ne olursa olsun veya yatırım tarzı!</p>
                <div className="service-card-two__icon">
                  <i className="icon-link"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg h-100">
                <h3 className="service-card-two__title"><Link to="/forex-education">Eğitim</Link></h3>
                <p className="service-card-two__text">Eğitim almanıza yardımcı olacak kişisel destek uzamanınız hazır!</p>
                <div className="service-card-two__icon">
                  <i className="icon-technical-support"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="service-card-two gray-bg h-100">
                <h3 className="service-card-two__title"><Link to="/forex-analytical">Ücretsiz Strateji</Link></h3>
                <p className="service-card-two__text">Uzmanlarımız tarafından ücretsiz stratejileri alın!
                </p>
                <div className="service-card-two__icon">
                  <i className="icon-analysis"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding--bottom about-six">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-six__content">
                <div className="section-title">
                  <p className="section-title__text">Omega</p>
                  <h2 className="section-title__title">Omega Invest Forex İşlemleri</h2>
                </div>
                <div className="about-six__text">+99 forex çiftleri</div>
                <div className="about-six__text">1:500 maksimum kaldıraç (değişkenlik gösterebilir)</div>
                <div className="about-six__text">Minimum yatırım tutarı 200 USD</div>
                <ul className="about-six__list">
                  <li className="about-six__list__item">
                    <i className="far fa-check-circle about-six__list__icon"></i>
                    <h3 className="about-six__list__title count-box"><span className="count-text"
                    ><AnimatedNumber value={9584} duration={1500} formatValue={(v) => Math.round(v)} /></span>
                    </h3>
                    <div className="about-six__list__text">Mutlu Müşteri</div>
                  </li>
                  <li className="about-six__list__item">
                    <i className="far fa-check-circle about-six__list__icon"></i>
                    <h3 className="about-six__list__title count-box"><span className="count-text"
                    >$<AnimatedNumber value={300} duration={1500} formatValue={(v) => Math.round(v)} />M+</span>
                    </h3>
                    <div className="about-six__list__text">Ortalama Aylık İşlem Hacmi</div>
                  </li>
                </ul>
                {/* <div className="about-six__btns">
                  <Link to="/about" className="thm-btn"><span>learn more</span></Link>
                  <Link to="/service-01" className="about-six__link">
                    How it Work
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-six__images wow fadeInUp" data-wow-duration="1500ms">
                <img src="assets/images/resources/about-1.png" alt="" />
                <img src="assets/images/resources/about-2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding--top section-padding--bottom service-three black-bg"
        style={{ backgroundImage: `url(${BG3})` }}>
        <div className="container">
          <div className="service-three__block">
            <div className="row">
              <div className="col-lg-7">
                <div className="section-title">
                  <p className="section-title__text">Platformlarımız</p>
                  <h2 className="section-title__title section-title__title--light">Güncel Son Teknoloji Platformlarımızda Trade işlemlerinizi Rahatlıkla Yapabilirsiz!</h2>
                </div>
              </div>
              {/* <div className="col-lg-5">
                <div className="service-three__block__text">100.000 deneme bonusu : Demo hesabını aç ; 100.000 dolar deneme bonusuyla Forex piyasasını tanı.</div>
                <div className="service-three__block__text"></div>
                <div className="service-three__block__text"></div>
              </div> */}
            </div>
          </div>
          <ul className="service-three__list">
            <a href={"https://" + wt} target="_blank">
              <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="000ms">
                <i className="icon-coding service-three__list__icon"></i>
                <h3 className="service-three__list__title">Web</h3>
              </li>
            </a>
            <a href="https://apps.apple.com/al/app/box-tool/id6499506604" target="_blank">
              <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="100ms">
                <i className="icon-apple service-three__list__icon"></i>
                <h3 className="service-three__list__title"><Link to="#">IOS</Link></h3>
              </li>
            </a>
            <a href="https://play.google.com/store/search?q=app4world&c=apps&gl=TR" target="_blank">
              <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="200ms">
                <i className="icon-android service-three__list__icon"></i>
                <h3 className="service-three__list__title">Android</h3>
              </li>
            </a>
            {/* <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
              data-wow-delay="300ms">
              <i className="icon-iot service-three__list__icon"></i>
              <h3 className="service-three__list__title"><Link to="#">IOT</Link></h3>
            </li>
            <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
              data-wow-delay="400ms">
              <i className="icon-smartband service-three__list__icon"></i>
              <h3 className="service-three__list__title"><Link to="#">Wearalables</Link></h3>
            </li>
            <li className="service-three__list__item wow fadeInUp" data-wow-duration="1500ms"
              data-wow-delay="500ms">
              <i className="icon-tv service-three__list__icon"></i>
              <h3 className="service-three__list__title"><Link to="#">TV</Link></h3>
            </li> */}
          </ul>
        </div>
      </section>

      < section className="section-padding--bottom section-padding--top service-one" >
        <div className="container-fluid">

          <OwlCarousel className="thm-owl__carousel service-one__carousel" {...serviceOptions}>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src="assets/images/services/service-1.png" alt="" />
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="/">100.000 USD Deneme Bonusu</Link></h3>
                  <p className="service-card-one__text">100.000 USD deneme bonusuyla Forex piyasasını tanı.</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src="assets/images/services/service-2.png" alt="" />
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="/">Forex’i Öğrenin</Link></h3>
                  <p className="service-card-one__text">Global piyasalarda işlem yapmayı öğrenin , yatırım fırsatlarını kaçırmayın!</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-card-one gray-bg">
                <div className="service-card-one__image">
                  <img src="assets/images/services/service-3.png" alt="" />
                </div>
                <div className="service-card-one__content">
                  <h3 className="service-card-one__title"><Link to="/">Çift Yönlü İşlem</Link></h3>
                  <p className="service-card-one__text">İşlem yapacağınız finansal enstrümanların en düşüşünden hem de yükselişinden kazanın!</p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section >

      <section
        className="section-padding--bottom section-padding--top gray-bg testimonials-one background-repeat-no background-position-top-center"
        style={{ backgroundImage: `url(${BG4})` }}>
        <div className="container">
          <div className="section-title text-center">
            <p className="section-title__text">Omega Uzman Kadrosu</p>
            <h2 className="section-title__title">5/24 kişiye özel yatırım danışmanınızla birlikte Omega’nın geniş ürün yelpazesinden yararlanın ve kazançlarınızı katlayın.</h2>
          </div>
          {/* <div className="row gutter-y-30">
            <div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
                  <img src="assets/images/resources/testi-1-1.jpg" alt="" />
                </div>
                <div className="testimonials-one-card__content">
                  <div className="testimonials-one-card__text">On the other hand denounc with ghteo
                    indignation and dislike men who so beguiled and demoralized the charms of pleasure
                    the momen blinded by desire cannot foresee the pain and trouble.</div>
                  <h3 className="testimonials-one-card__title">Michal Rahul</h3>
                  <p className="testimonials-one-card__designation">Ul - UX Designer</p>
                  <i className="icon-right-quote testimonials-one-card__icon"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonials-one-card">
                <div className="testimonials-one-card__image">
                  <img src="assets/images/resources/testi-1-2.jpg" alt="" />
                </div>
                <div className="testimonials-one-card__content">
                  <div className="testimonials-one-card__text">On the other hand denounc with ghteo
                    indignation and dislike men who so beguiled and demoralized the charms of pleasure
                    the momen blinded by desire cannot foresee the pain and trouble.</div>
                  <h3 className="testimonials-one-card__title">Jessica Brown</h3>
                  <p className="testimonials-one-card__designation">Ul - UX Designer</p>
                  <i className="icon-right-quote testimonials-one-card__icon"></i>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>


      <section className="section-padding--bottom section-padding--top project-two mb-4">
        <div className="container-fluid">
          <div className="section-title text-center">
            <p className="section-title__text">Neden Omega</p>
            <h2 className="section-title__title">Omega'nın Avantajlarıyla Trade İşlemlerinize Hemen Başlayın</h2>

          </div>

          <OwlCarousel className="thm-owl__carousel" {...projectOptions} >
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src="assets/images/projects/slider-1.png" alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">Geniş Ürün Yelpazesi</p>
                    <h3 className="project-card-one__title"><Link to="/about">Geniş Ürün Yelpazemizi Sizde Keşfedin!</Link></h3>
                    <Link to="/about" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src="assets/images/projects/slider-2.png" alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">Hızlı Alt Yapısı</p>
                    <h3 className="project-card-one__title"><Link to="/about">Hızlı ve Sorunsuz Altyap Desteğimiz!</Link></h3>
                    <Link to="/about" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src="assets/images/projects/slider-3.png" alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">5/24 Destek</p>
                    <h3 className="project-card-one__title"><Link to="/about">Uzman Ekibimiz 5/24 Sizinle!</Link></h3>
                    <Link to="/about" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src="assets/images/projects/slider-4.png" alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">Ödeme Güvenliği</p>
                    <h3 className="project-card-one__title"><Link to="/about">Güvenli Ödeme Sistemizle Güvenle Para Yatırabilirsiniz</Link></h3>
                    <Link to="/about" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src="assets/images/projects/slider-5.png" alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">Çeşitli Çekim Seçenekleri</p>
                    <h3 className="project-card-one__title"><Link to="/about">Birden Fazla Çekim Seçenkleriyle Paranızı Güvenle Çekin</Link></h3>
                    <Link to="/about" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="project-card-one">
                <div className="project-card-one__image">
                  <img src="assets/images/projects/slider-6.png" alt="" />
                </div>
                <div className="project-card-one__content">
                  <div className="project-card-one__content__inner">
                    <p className="project-card-one__text">Kişiye Özel Yatırım Danışmanı</p>
                    <h3 className="project-card-one__title"><Link to="/about">Sadece Size Özel Olan Danışmanınızla Yatırmıza Yön Verin!</Link></h3>
                    <Link to="/about" className="project-card-one__more">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      {/* <section className="section-padding--top section-padding--bottom contact-two">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="contact-two__image">
                <img src="assets/images/resources/call.png" className="wow fadeInUp"
                  data-wow-duration="1500ms" alt="" />
                <Link to="#"
                  className="video-popup contact-two__video">
                  <i className="fa fa-play"></i>
                  <i className="ripple"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-two__content">
                <div className="section-title">
                  <p className="section-title__text section-title__text--light">İletişime Geç!</p>

                  <h2 className="section-title__title section-title__title--light"></h2>
                </div>
                <form action="assets/inc/sendemail.php" className="contact-one__form contact-form-validated">
                  <div className="row ">
                    <div className="col-lg-6 col-md-12">
                      <input type="text" placeholder="Your name" name="name" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input type="email" placeholder="Email address" name="email" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <input type="text" placeholder="Phone" name="phone" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <select name="services" className="niceselect">
                        <option value="department">Department</option>
                        <option value="department">Department</option>
                        <option value="department">Department</option>
                        <option value="department">Department</option>
                      </select>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <textarea name="message" placeholder="Write message"></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="thm-btn thm-btn--light contact-one__btn" type="submit"><span>send
                        message</span></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <RealAccountModal open={openReal} setOpenReal={setOpenReal} />



      {/* <div className="client-carousel client-carousel--two">
        <div className="container">
          <OwlCarousel className="thm-owl__carousel" {...logoOptions}>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
            <div className="item">
              <img src="assets/images/resources/client-1-1.png" alt="" />
            </div>
          </OwlCarousel>
        </div>
      </div> */}
    </>
  )
}

export default Home3