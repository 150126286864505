import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import DemoAccountModal from '../Modals/DemoAccountModal';
import RealAccountModal from '../Modals/RealAccountModal';
import WOW from 'wowjs'
import Logo from '../../assets/images/logo-light.png'
import { motion } from "framer-motion"
import CampaignList from '../Campaigns/Campaigns'

function MyNavbar() {
    const location = useLocation()
    const path = location.pathname

    const [sticky, setSticky] = useState(false);
    const [openReal, setOpenReal] = useState(false)
    const [openDemo, setOpenDemo] = useState(false)
    const [mobile, setmobile] = useState(false)

    const [menu, setmenu] = useState({})
    const [home, setHome] = useState(false)
    const [header, setheader] = useState(false)
    const [pages, setpages] = useState(false)
    const [campaign, setCampaign] = useState(false)
    const [service, setservice] = useState(false)
    const [project, setproject] = useState(false)
    const [campaigns, setCampaigns] = useState(false)
    const [forex, setForex] = useState(false)
    const [blog, setblog] = useState(false)

    const [toggle, setToggle] = useState(false)
    const [xDirection, setXDirection] = useState(0)

    const [matches, setMatches] = useState(window.matchMedia("(max-width: 1800px)").matches);

    useEffect(() => {
        const handler = e => setMatches({ matches: e.matches });
        window.matchMedia("(max-width: 1800px)").addEventListener('change', handler);
    })

    const login = "sca." + window.location.hostname.replace('www.', '')
    const wt = "wt." + window.location.hostname.replace('www.', '')
    const register = 'my.' + window.location.hostname + "/register?lang=tr"

    const activeMenu = () => {
        if (path === "/") {
            setmenu({ home: true })
        } else if (path === "/about") {
            setmenu({ pages: true })
        } else if (path === "/campaigns" || path.includes('campaigns')) {
            setmenu({ campaign: true })
        } else if (path === "/trading-platforms" || path === "/service-02" || path === "qa-testing" || path === "/it-management" || path === "cyber-security" || path === "/it-consultant" || path === "/infrastructure-plan") {
            setmenu({ services: true })
        } else if (path === "/what-is-forex" || path === "/forex-education" || path === "/forex-analytical" || path === "/notices" || path === "/cookie-policy") {
            setmenu({ project: true })
        } else if (path === "/blog" || path === "/blog-details") {
            setmenu({ blog: true })
        } else if (path === "/contact") {
            setmenu({ contact: true })
        }
        // else if (path === "/contact") {
        //     setmenu({ contact: true })
        // } 
        else {
            setmenu({ home: false })
        }
    }
    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
        setmobile(false)
    }, [path])

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    const openNewPage = (page) => {
        window.open(`https://${page}`, '_blank')
    }

    // <li className="menu-item-has-children">
    //                                     <a href={`https://${login}`} target="_blank">
    //                                         <b className='text-left mt-2 mx-2 text-gold'>GİRİŞ</b>
    //                                     </a>
    //                                 </li>
    //                                 <li className="menu-item-has-children">
    //                                     <a href={`https://${wt}`} target="_blank">
    //                                         <b className='text-left mt-2 mx-2 text-gold'>Web Trader</b>
    //                                     </a>
    //                                 </li>

    const toggleNavbar = () => {
        setXDirection(-180)
        setToggle(!toggle)
        if (toggle) {
            <motion.div
                initial={{ width: "0vw", x: "50vw" }}
                animate={{ width: "50vw", x: 0 }}
                transition={{ duration: 1, origin: 1 }}
            />
        }
    }

    return (
        <div className="header-three">
            <div className="topbar">
                <div className="container-fluid">
                    <p className="topbar__text">Omega Invest</p>
                    <ul className="topbar__info">

                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:support@omega-market.com">support@omega-market.com</a>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            60 Cleveland St, Bt Tower, Londra
                        </li>

                    </ul>
                    {/* <ul className="topbar__social">
                        <li><a href='https://twitter.com/regnummenkul' target='_blank'><i className="fab fa-twitter"></i></a></li>
                        <li><a href='https://www.facebook.com/Regnummenkul-110766585298918/?_rdr' target='_blank'><i className="fab fa-facebook" target='_blank'></i></a></li>
                        <li><a href='https://www.instagram.com/regnummenkul/' target='_blank'><i className="fab fa-instagram"></i></a></li>
                    </ul> */}
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`} style={{ backgroundColor: `${!menu.home ? "#12062C" : ""}` }}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/">
                            <img src={Logo} width="160" height="70" alt="Omega" />
                        </Link>
                    </div>

                    <ul className="main-menu__list">
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">ANASAYFA</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.pages && "current"}`}>
                            <Link to="/about">Hakkımızda</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <Link to="/trading-platforms">İşlem Platformları</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.campaign && "current"}`}>
                            <Link to="/campaigns">Kampanyalar</Link>
                            <ul className='navbar-campaign-dropdown'>
                                {
                                    CampaignList.map((item) => {
                                        return (
                                            <li key={item.id}>
                                                <Link to={`/campaigns/${item.url}`} state={item} className='one-line-truncate'>{item.title}</Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </li>
                        <li className={`menu-item-has-children ${menu.project && "current"}`}>
                            <Link to="/what-is-forex">Forex</Link>
                            <ul>
                                <li><Link to="/what-is-forex">FOREX NEDİR</Link></li>
                                <li><Link to="/forex-education">FOREX EĞİTİMİ</Link></li>
                                <li><Link to="/forex-analytical">FOREX ANALİTİĞİ</Link></li>
                                <li><Link to="/notices">Yasal Uyarılar</Link></li>
                                <li><Link to="/cookie-policy">ÇEREZ POLİTİKASI</Link></li>
                            </ul>
                        </li>
                        <li className="menu-item-has-children">
                            <Link to="/">Hesap Aç</Link>
                            <ul className='menu-item-custom-width'>
                                <li><Link to="/" onClick={() => setOpenReal(true)}>Gerçek Hesap Oluştur</Link></li>
                                <li><Link to="/" onClick={() => setOpenDemo(true)}>Demo Hesap Olutur</Link></li>
                            </ul>
                        </li>
                        <li className={`menu-item-has-children ${menu.contact && "current"}`}
                        ><Link to="/contact">İLETİŞİM</Link></li>
                    </ul>

                    <div className="main-menu__right">
                        <button to="#" className="btn mobile-nav__toggler" onClick={() => setmobile(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>

                        <div className="main-menu__cta navbar-custom-menu" onMouseEnter={toggleNavbar} onMouseLeave={() => setToggle(false)}>
                            {
                                matches &&
                                (toggle ?
                                    <>
                                        <motion.div
                                            initial={{ height: "2vw", y: 200, x: 125, width: "150px" }}
                                            animate={{ y: 50 }}
                                            transition={{ duration: 0.7, origin: 1 }}
                                            className="motion-user-custom"
                                        >
                                            <div className='navbar-menu-hover align-items-center'>
                                                <span className="main-menu__cta__text">
                                                    <b style={{ minWidth: '5rem' }} className='text-left mobile-navbar-animate-item'>
                                                        <a href={"https://" + login} target="_blank" className="text-white">Giriş Yap</a>
                                                    </b>
                                                </span>
                                                <span className="main-menu__cta__text">
                                                    <b style={{ minWidth: '6rem' }} className='text-left mobile-navbar-animate-item'>
                                                        <a href={"https://" + wt} target="_blank" className="text-white">Web Trader</a>
                                                    </b>
                                                </span>
                                            </div>
                                        </motion.div>
                                    </>
                                    :
                                    null)
                            }
                            {
                                !matches &&
                                (toggle ?
                                    <>
                                        <motion.div
                                            initial={{ width: "2vw", x: "10vw" }}
                                            animate={{ x: xDirection }}
                                            transition={{ duration: 0.7, origin: 1 }}
                                        >
                                            <div className='navbar-menu-hover d-flex align-items-center'>
                                                <span className="main-menu__cta__text">
                                                    <b style={{ minWidth: '5rem' }} className='text-left mt-2 mx-2'>
                                                        <a href={"https://" + login} target="_blank" className="text-white">Giriş Yap</a>
                                                    </b>
                                                </span>
                                                <span className="main-menu__cta__text">
                                                    <b style={{ minWidth: '6rem' }} className='text-left mt-2 mx-2'>
                                                        <a href={"https://" + wt} target="_blank" className="text-white">Web Trader</a>
                                                    </b>
                                                </span>
                                            </div>
                                        </motion.div>
                                    </>
                                    :
                                    null)
                            }
                            <span className="main-menu__cta__text" onClick={() => setOpenReal(true)}>
                                <b className='text-left mt-2 mx-2' style={{ cursor: "pointer" }}>Üye Ol</b>
                            </span>

                            <i className="fa-solid fa-user mx-2 navbar-icon" ></i>
                        </div>

                    </div>
                    <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
                        <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                        <div className="mobile-nav__content">
                            <button to="#" onClick={() => setmobile(false)} className="btn mobile-nav__close mobile-nav__toggler">
                                <span></span>
                                <span></span>
                            </button>

                            <div className="logo-box">
                                <Link to="/"><img src={Logo} width="98" height="33" alt="Omega" /></Link>
                            </div>
                            <div className="mobile-nav__container">
                                <ul className="mobile-menu__list">
                                    <li className={`menu-item-has-children ${menu.home ? "current" : ""}`}>
                                        <Link to="/" className={home ? "expanded" : ""} >ANASAYFA</Link>
                                    </li>
                                    <li className={`menu-item-has-children ${menu.pages ? "current" : ""}`}>
                                        <Link to="/about" className={pages ? "expanded" : ""}>HAKKIMIZDA</Link>
                                    </li>
                                    <li className={`menu-item-has-children ${menu.services ? "current" : ""}`}>
                                        <Link to="/trading-platforms" className={pages ? "expanded" : ""}>İŞLEM PLATFORMLARI</Link>
                                    </li>
                                    <li className={`menu-item-has-children ${menu.campaign ? "current" : ""}`}>
                                        <div className={`mobile-navbar-div-item ${pages ? "expanded" : ""}`}><Link to="/campaigns" style={{ color: "#877e9b" }}>KAMPANYALAR</Link><button style={{ backgroundColor: '#D4AF37' }} aria-label="dropdown toggler" onClick={() => setCampaign(!campaign)} className={campaign ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></div>
                                        {campaign &&
                                            <ul style={{ display: "block" }}>
                                                {
                                                    CampaignList.map((item) => {
                                                        return (
                                                            <li key={item.id}>
                                                                <Link to={`/campaigns/${item.url}`} state={item} className='one-line-truncate'>{item.title}</Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>}
                                    </li>
                                    <li className={`menu-item-has-children ${menu.project ? "current" : ""}`}>
                                        <div className={`mobile-navbar-div-item ${service ? "expanded" : ""}`}>FOREX<button style={{ backgroundColor: '#D4AF37' }} aria-label="dropdown toggler" onClick={() => setservice(!service)} className={service ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></div>
                                        {service &&
                                            <ul style={{ display: "block" }}>
                                                <li><Link to="/what-is-forex">FOREX NEDİR?</Link></li>
                                                <li><Link to="/forex-education">FOREX EĞİTİMİ</Link></li>
                                                <li><Link to="/notices">YASAL UYARILAR</Link></li>
                                                <li><Link to="/cookie-policy">ÇEREZ POLİTİKASI</Link></li>
                                            </ul>}
                                    </li>
                                    <li className="menu-item-has-children">
                                        <div className={`mobile-navbar-div-item ${project ? "expanded" : ""}`}>HESAPLAR<button style={{ backgroundColor: '#D4AF37' }} aria-label="dropdown toggler" onClick={() => setproject(!project)} className={project ? "expanded" : ""}><i className="fa fa-angle-down"></i></button></div>
                                        {project &&
                                            <ul style={{ display: "block" }}>
                                                <li><Link to="#" onClick={() => setOpenReal(true)}>Gerçek Hesap Oluştur</Link></li>
                                                <li><Link to="#" onClick={() => setOpenDemo(true)}>Demo Hesap Oluştur</Link></li>
                                            </ul>}
                                    </li>
                                    <li className={`menu-item-has-children ${menu.contact ? "current" : ""}`}><Link to="/contact">İletişim</Link></li>
                                    <li className="menu-item-has-children">
                                        <a href={`https://${login}`} target="_blank">
                                            <b className='text-left mt-2 text-yellow'>GİRİŞ YAP</b>
                                        </a>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <div className='mobile-navbar-register' onClick={() => setOpenReal(true)}>
                                            <b className='text-left mt-2 text-yellow'>ÜYE OL</b>
                                        </div>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <a href={`https://${wt}`} target="_blank">
                                            <b className='text-left mt-2 text-yellow'>Web Trader</b>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <ul className="mobile-nav__contact list-unstyled">
                                <li>
                                    <i className="fa fa-phone"></i>
                                    <a href="tel:+447916556530">+44 7916 556530</a>
                                </li>
                                <li>
                                    <i className="fa fa-envelope"></i>
                                    <a href="mailto:support@omega-market.com">
                                        support@omega-market.com</a>
                                </li>
                                <li>
                                    <i className="fa fa-map-marker-alt"></i>
                                    60 Cleveland St, London<br />Birleşik Krallık
                                </li>
                            </ul>
                            {/* <ul className="mobile-nav__social">
                                <li><a href='https://twitter.com/regnummenkul' target='_blank'><i className="fab fa-twitter"></i></a></li>
                                <li><a href='https://www.facebook.com/Regnummenkul-110766585298918/?_rdr' target='_blank'><i className="fab fa-facebook" target='_blank'></i></a></li>
                                <li><a href='https://www.instagram.com/regnummenkul/' target='_blank'><i className="fab fa-instagram"></i></a></li>
                            </ul> */}
                        </div>
                    </div>

                </div>
                <RealAccountModal open={openReal} setOpenReal={setOpenReal} />
                <DemoAccountModal open={openDemo} setOpenDemo={setOpenDemo} />
            </nav>
        </div>
    )
}

export default MyNavbar