import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'

function CookiePolicy() {
  return (
    <>
      <Suspense fallback={
        <div class="preloader">
          <div class="preloader__circle"></div>
        </div>} >
        {/* <Breadcumb Title="Çerez Politikası" Breadcumb="FOREX" /> */}
        <section className="section-padding--bottom section-padding--top service-details--page mt-5">
          <div className="container">
            <div className="row ">
              <div className="col-lg-8">
                <div className="service-details__image">
                  <img src="assets/images/services/cookie-policy.png" alt="" />
                </div>
                <h3 className="service-details__title">Çerez Politikamız</h3>
                <div className="service-details__content">
                  <p>Omega Invest'de tüm müşteri bilgileri güvenli bir veri tabanında tutulmakta olup, sadece yetkili personelin erişimi mümkündür. Müşteri gizliliği, müşteri hizmetlerimizin bir parçası olup, gizliliğinizi korumak, birinci önceliğimizdir.</p>
                  <h4>Omega Invest Gizlilik ve Çerez Politikası</h4>
                  <p>Omega Invest olarak, müşteri gizliliğini müşteri hizmetlerimizin bir parçası olarak görmekte olup, gizliliğinizi korumak birinci önceliğimizdir. Gizliliğiniz aynı zamanda yasalar ile de korunmaktadır.</p>
                  <p>Omega Invest nezdinde bir deneme hesabı veya gerçek hesap açtığınızda, öncelikle tabi olduğumuz yasal mevzuat ya da sözleşmesel yükümlülüklerin yerine getirilmesi veya hizmet sunumunun ihtiyaçları doğrultusunda hakkınızdaki bilgiler toplanarak, işlenmekte ve saklanmaktadır. Tüm müşteri bilgileri güvenli bir veri tabanında tutulmakta olup, hukuka aykırı erişim ve işlemenin engellenmesi konusunda idari ve teknik tedbirler alınmıştır. Bu bilgiler, sizin tarafımıza sunacağınız bilgiler olabileceği gibi ayrıca sitemizi ve platformlarımızı ziyaretiniz sırasınca otomatik olarak sisteme bırakacağınız teknik bilgiler, tarayıcı, işletim sistemi, ziyaret kaynaklarınız, ziyaret saatleriniz, yönlendirme adresleri gibi bilgiler de olabilir.</p>
                  <p>Kişisel verilerinizin elde etme yöntemi, işlenme amaçları, süresi ve aktarıma konu olup olmadığı ile kişisel verilerinize ilişkin haklarınız “Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni”nde daha kapsamlı şekilde açıklanmaktadır. Kişisel verilerinizin işlenmesindeki en temel amaç, yasal olarak yerine getirmemiz gereken 'Müşterini Tanı' standardını yakalamak ve müşterinin yaptığı işlemlerde güvenliğini sağlamaktır.</p>
                  <p>Omega Invest, internet sitesi ziyaretiniz sırasında bilgi toplayabilmek için tanımlama bilgilerinden (cookies) yararlanmaktadır. Tanımlama bilgisi, bir web sitesini ziyaret ettiğinizde söz konusu web sitesinin kayıt tutmak amacıyla bilgisayarınızın sabit sürücüsünde muhafaza ettiği küçük veri dosyasıdır. Sitemiz tarafından çerez kullanılmasını istemiyor iseniz bilgisayar ayarlarınızda gerekli değişiklikleri yapmanızı ya da sitemizi ziyaret ettikten sonra tarayıcı programınızın “tarih (history)” kısmından sitemize ilişkin gezinti kayıtlarınızı silmenizi öneririz.</p>
                  <p>Tanımlama bilgileri, örneğin parolalarınızı ve görüntüleme tercihlerinizi hatırlayıp böylece web sitesinin çeşitli “üyelere özel” kısımlarını yeniden sisteme giriş yapmadan ziyaret etmenize imkân vererek, kullanım rahatlığınızı geliştirmemizi sağlar. Ayrıca, web sitesindeki faaliyet miktarını ölçümleyip popüler olan ve olmayan alanları belirleyerek iyileştirme ve güncellemeler yapmak amacıyla biz de tanımlama bilgilerinden yararlanırız.</p>
                  <p>Tanımlama bilgilerini, aslen bir tanımlama bilgisi ile gönderilmeyen bilgileri almak amacıyla kullanmayız. Tanımlama bilgileri yoluyla aktarılan bilgileri, sizin rızanız olmadan hiçbir şekilde doğrudan tanıtım veya pazarlama amacı doğrultusunda kullanmayacağımızı taahhüt ederiz.</p>
                  <p>Sitemizde yer alan linkleri tıklamak suretiyle ulaşacağınız ve Omega Invest tarafından yönetilmeyen sitelerdeki gizlilik, kişisel verilerin korunması ve diğer mevzuat kapsamındaki iş ve işlemlerden Omega Invest sorumlu değildir. Söz konusu sitelerdeki verilerin doğru olmamasından, yanlış ve yanıltıcı olmasından Omega Invest sorumlu tutulamaz. Omega Invest internet sitesinde kendisi tarafından sağlanan içeriğin doğru olması, yanlış ve yanıltıcı olmaması ve içeriğin her zaman ulaşılabilir olması konusunda maksimum özeni gösterir. Buna rağmen ulaşılamaması ya da içeriğin niteliğine ilişkin uyuşmazlıkların ortaya çıkması durumunda Omega Invest objektif kriterler çerçevesinde kendisine yüklenemeyecek aksaklık, hata ve kusurdan sorumlu değildir.</p>
                  <p>Zaman zaman, bu Gizlilik Politikası'nda günün gereksinimleri, müşteri memnuniyeti ve kanuni sebeplerden dolayı güncellemeler yapabiliriz. Gizlilik Beyanının değişmesi durumunda, değişen Gizlilik Politikası hemen web sitesi üzerinden yayınlanır ve web sitelerimizde bu gibi değişikliklerin bilgisini açıklayan duyuru yapılır.</p>
                </div>
              </div>
              <div className="col-lg-4 sidebar-column">
                <ServiceSidebar />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  )
}

export default CookiePolicy