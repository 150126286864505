import React, { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import Breadcumb from '../../Breadcumb/Main'
import CampaignList from '../Campaigns'
import { Helmet } from 'react-helmet'

const ProjectDetails = () => {

	const location = useLocation()
	let item = location.state


	const options = {
		loop: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		smartSpeed: 700,
		items: 1,
		margin: 0,
		nav: false,
		dots: false,
		responsive: {
			0: {
				items: 1,
				margin: 0,
				loop: true
			},
			576: {
				items: 2,
				margin: 30,
				loop: true
			},
			992: {
				items: 3,
				margin: 30,
				loop: true
			}
		}
	}
	const conditions = Object.values(item.conditions);
	const warnings = Object.values(item.warnings);
	return (
		<>
			{/* <Breadcumb Title={item.title} Breadcumb="KAMPANYALAR" /> */}
			<section className="project-details section-padding--bottom section-padding--top mt-5">
				<Helmet>
					<meta name="description"
						content="Omega farkıyla forex piyasalarına girmeye hazır mısınız? Hemen Omega’lı olun ; forex piyasalarının keyfini Omega’le çıkarın." />
					<title>{item.title} - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
				</Helmet>
				<div className="container">
					<div className="project-details__image mb-4">
						<img src={`../../../assets/${item.imageUrl.bigImage}`} alt="" />
					</div>
					<h3 className="project-details__title">{item.title}</h3>
					<div className="project-details__content">
						{
							conditions.map((item, key) => {
								return (
									<p key={key} className='mt-4'>{item}</p>
								)
							})
						}
						<ul className="project-details__list">
							{
								warnings.map((item, key) => {
									return (
										<li key={key} className='mb-3'>
											<i className="fa fa-check-circle"></i>
											{item}
										</li>
									)
								})
							}
						</ul>
					</div>
				</div>
			</section>

			{/* <div className="project-nav">
				<div className="container">
					<div className="project-nav__inner">
						<Link to="#">
							<i className="icon-left-arrow"></i>
							Önceki
						</Link>
						<Link to="#">
							Sonraki
							<i className="icon-right-arrow"></i>
						</Link>
					</div>
				</div>
			</div> */}

			<section className="section-padding--top section-padding--bottom">
				<div className="container">
					<div className="section-title text-center">
						<p className="section-title__text">Benzer Kampanyalar</p>
						<h2 className="section-title__title">Diğer Kampanyalarımızı <br /> Görüntüleyin</h2>
					</div>

					<OwlCarousel className="thm-owl__carousel" {...options}>
						{CampaignList.map((cam) => (
							cam.id !== item.id ? <Link to={`/campaigns/${cam.url}`} state={cam} key={cam.id}>
								<div className="item">
									<div className="project-card-one">
										<div className="project-card-one__image">
											<img src={`../../../assets/${cam.imageUrl.smallImage}`} alt="" />
										</div>
										<div className="project-card-one__content">
											<div className="project-card-one__content__inner">
												<h3 className="project-card-one__title"><div className='campaign-card-title'>{cam.title}</div></h3>
												<div className="project-card-one__more">
													<i className="fa fa-angle-right"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Link> : null
						))}
					</OwlCarousel>
				</div>
			</section>
		</>
	)
}

export default ProjectDetails