import React, { Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../../Breadcumb/Main'
import ServiceSidebar from '../ServiceSidebar/Main'
import { Helmet } from 'react-helmet'

const WhatisForex = () => {

	return (
		<>
			<Suspense fallback={
				<div class="preloader">
					<div class="preloader__circle"></div>
				</div>} >
				{/* <Breadcumb Title="Forex Nedir" Breadcumb="SERVICES" /> */}
				<section className="section-padding--bottom section-padding--top service-details--page mt-5">
					<Helmet>
						<meta name="description"
							content="Omega farkıyla forex piyasalarına girmeye hazır mısınız? Hemen Omega’li olun ; forex piyasalarının keyfini Omega’le çıkarın." />
						<title>Forex - Omega Invest - Forex Piyaslarının En Yeni Üyesi</title>
					</Helmet>
					<div className="container">
						<div className="row ">
							<div className="col-lg-8">
								<div className="service-details__image">
									<img src="assets/images/services/what-is-forex.png" alt="" />
								</div>
								<h3 className="service-details__title">Forex Nedir?</h3>
								<div className="service-details__content">
									<p>Foreign exchange ticareti bir ülkenin parasını başka bir ülkenin parasıyla ticaret yapması veya bir cinsten
										dövizin verilerek karşılığında kotasyonu ilan edilmiş fiyattan diğer bir cins dövizin alınması işlemi olarak da tanımlanabilir.</p>
									<h4>Dünyanın en büyük ve en hızlı gelişen finansal piyasası konumunda olan Forex piyasaları, işlem hacmi ile tüm dünya hisse senetleri borsalarının toplamından da fazla likiditeye sahiptir.</h4>
									<p>Dünya genelinde forex işlemlerine aracılık eden on binlerce banka ve aracı kurum bulunur. Temel işleyiş aynı olsa da piyasa kuralları
										ülkedeki düzenlemelere göre değişiklik gösterebilir. Bazı aracı kurumların 1:400 hatta 1:1000 oranında kaldıraç kullanmaya izin verdiğini
										görebilirsiniz. Ancak bu kadar yüksek oranda kaldıraç kullanılmasının, genellikle yüksek oranda kayıp ile sonuçlandığı görülmüştür.
										Tam da bu nedenle, yatırımcıların korunması ve risklerinin sınırlandırılması maksadıyla Türkiye’de kaldıraç oranı Sermaye Piyasası
										Kurulu tarafından 1:10 olarak düzenlenmiştir.</p>
								</div>
							</div>
							<div className="col-lg-4 sidebar-column">
								<ServiceSidebar />
							</div>
						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}

export default WhatisForex