import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'
import Footer from '../components/Footer/Main'
import Campaigns from '../components/Campaigns/Campaigns/Main'
import CampaignsDetails from '../components/Campaigns/CampaignsDetails/Main'
import Home from '../components/Home/Main'
import Home2 from '../components/Home2/Main'
import Home3 from '../components/Home3/Main'
import Navbar from '../components/Navbar/Main'
import ProjectDetails from '../components/Project/ProjectDetails/Main'
import Projects from '../components/Project/Projects/Main'
import WhatIsForex from '../components/Services/WhatIsForex/Main'
import Service1 from '../components/Services/Services1/Main'
import Service2 from '../components/Services/TradingPlatforms/Main'
import Team from '../components/Team/Main'
import ForexEducation from '../components/Services/ForexEducation/Main'
import LegalNotices from '../components/Services/LegalNotices/LegalNotices'
import CookiePolicy from '../components/Services/CookiePolicy/CookiePolicy'
import ForexAnalytical from '../components/Services/ForexAnalytical/Main'
import NotFound from '../components/NotFound/NotFound'
import MyNavbar from '../components/Navbar/MyNavbar'
import License from '../components/License/License'

const Routing = () => {
  const [homepage, sethomepage] = useState(false)
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/home-03") {
      sethomepage(false)
    } else {
      sethomepage(true)
    }
  }, [location])

  return (
    <>
      <MyNavbar />
      <Routes>
        {/* <Route path="/" element={<Home />} />
                <Route path="/home-02" element={<Home2 />} /> */}
        <Route path="/" element={<Home3 />} />
        <Route path="/about" element={<About />} />
        <Route path="/service-01" element={<Service1 />} />
        <Route path="/trading-platforms" element={<Service2 />} />
        <Route path="/what-is-forex" element={<WhatIsForex />} />
        <Route path="/forex-education" element={<ForexEducation />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/notices" element={<LegalNotices />} />
        <Route path="/forex-analytical" element={<ForexAnalytical />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/license" element={<License />} /> */}
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/campaigns/:campaignUrl" element={<CampaignsDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}

export default Routing