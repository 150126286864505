import axios from "axios"

let baseService = {
    post: async (url, data) => {
        let response
        await axios.post(url, data)
            .then((res) => response = res.data)
            .catch(err => response = err)
            return response
    }
}

export default baseService